import React, { useEffect, useState } from "react";
import "./SingleJobDetails.scss";

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import JobForm from "../JobForm/JobForm";

//done die done
function SingleJobDetails() {
  const { id } = useParams();

  const [singleJobPost, setSingleJobPost] = useState();
  const getSinglePage = () => {
    const config = {
      url: process.env.REACT_APP_BASE_URL + `api/getJobPostById/${id}`,
      method: "get",
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Hello");
        console.log(response?.data);
        if (response?.data?.status == true) {
          console.log("dfghj", response?.data);
          setSingleJobPost(response?.data?.data);
          // toast.success("Blog Get Successfully", {
          //   position: "bottom-right",
          //   autoClose: 4000,
          //   pauseOnHover: true,
          //   draggable: true,
          //   theme: "dark",
          // });
        }
        if (response?.data?.status == false) {
          if (response?.data?.response_code == 500) {
            toast.error("Problem With Data Fetching", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          } else if (response?.data?.response_code == 404) {
            toast.error("Problem With Data Fetching", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.response_code == 401) {
          toast.error("Your token is expired. Please login again.", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        } else {
          toast.warn("Problem With Data Fetching", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        }
      });
  };
  //done check
  useEffect(() => {
    getSinglePage();
  }, []);

  const [expanded, setExpanded] = useState(false);

  const expandMenu = () => {
    setExpanded((prev) => !prev);
  };
  const closeForm = () => {
    setExpanded(false);
  };

  const shareUrl = window.location.href;

  //done
  const navigate = useNavigate();

  function formatNumber(value) {
    console.log(value);
    if (!value) {
      return value;
    }
    if (value >= 10000000) {
      return (value / 10000000).toFixed(1) + " Cr"; // Crores
    } else if (value >= 100000) {
      return (value / 100000).toFixed(1) + " L"; // Lakhs
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + " K"; // Thousands
    }
    return value.toString();
  }

  return (
    <>
      <div className="singleJob">
        <button className="back" onClick={() => navigate("/career#AllJob")}>
          <i
            className="bi bi-arrow-left-circle fs-4"
            style={{ color: "white" }}
          ></i>
        </button>
        <div className="single1-div">
          <h1 className=" productHead">{singleJobPost?.role}</h1>
        </div>
        <div className="single2-div">
          <div className="singleLeft">
            <div className="detailss">
              <div className="div-a">
                <h2 className="pro-heading">{singleJobPost?.department}</h2>
                <h4>Job Description</h4>
                <ul>
                  {singleJobPost?.looking?.map((item) => {
                    return <li>{item}</li>;
                  })}
                </ul>
              </div>
              <div className="div-a">
                <h4>Skill</h4>
                <ul>
                  {singleJobPost?.doing?.map((item) => {
                    return <li>{item}</li>;
                  })}
                </ul>
              </div>
              <div className="div-a">
                <h4>Bonus Points for Familiarity with</h4>
                <ul>
                  <li> Paid time off.</li>
                  <li>Paid Sick time.</li>
                  <li>yearly bonus.</li>
                  <li>leave encashment.</li>
                  <li>flexible schedule.</li>
                </ul>
              </div>
              <div className="div-b">
                <h4>Educational Requirement</h4>
                <p>{singleJobPost?.qualification}</p>
              </div>
              <div className="div-a">
                <h4>Salary Range</h4>
                <ul>
                  <li>
                    <h5>
                      {formatNumber(singleJobPost?.lowSalary)} to{" "}
                      {formatNumber(singleJobPost?.highSalary)}
                    </h5>
                  </li>
                </ul>
              </div>

              <hr />
              <div className="div-a">
                <h4>The Application Process</h4>
                <ul>
                  <li>Resume screening .</li>
                  <li>Resume shortlist.</li>
                  <li>First Telephonic interview via HR.</li>
                  <li>Virtual Round of interview.</li>
                  <li>Assignment if needed.</li>
                  <li>
                    Assignment selected then final round of interview it would
                    be in- person/ offline.
                  </li>
                  <li>Selection / Rejection done.</li>
                </ul>
              </div>
              <div className="divC">
                <p></p>
                <div className="btn-outer text-end">
                  <button className="aply-btn text-end" onClick={expandMenu}>
                    Apply Now
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="singleRight">
            <div className="outerDiv " >
              <div className="box-outer-btn">
                <button className="aply-btn" onClick={expandMenu}>
                  Apply Now
                </button>
              </div>
              <h5 className="">Job Summary</h5>
              <div className="div-box">
                <div className="left">
                  <i class="bi bi-geo-alt-fill"></i>
                </div>
                <div className="right">
                  <p className="right-para">Location</p>
                  <p> Indore MP</p>
                </div>
              </div>
              <div className="div-box">
                <div className="left">
                  <i class="bi bi-briefcase"></i>
                </div>
                <div className="right">
                  <p className="right-para">Job Type</p>
                  <p>Full Time</p>
                </div>
              </div>
              <div className="div-box">
                <div className="left">
                  <i class="bi bi-journal-plus"></i>
                </div>
                <div className="right">
                  <p className="right-para">Date Posted</p>
                  <p>Posted One Month</p>
                </div>
              </div>
              <div className="div-box">
                <div className="left">
                  <i class="bi bi-book"></i>
                </div>
                <div className="right">
                  <p className="right-para">Experiance</p>
                  <p>
                    {singleJobPost?.lowExperience} to{" "}
                    {singleJobPost?.highExperience} year
                  </p>
                </div>
              </div>
              <div className="div-box">
                <div className="left">
                  <i class="bi bi-person-plus-fill"></i>
                </div>
                <div className="right">
                  <p className="right-para">Vacancy</p>
                  <p>No Of Vacancy : {singleJobPost?.vacancy}</p>
                </div>
              </div>
              <h5>
                <a href="/career">Veiw all job</a>
              </h5>
            </div>

            <div className="social-icons">
              <h6>Share This</h6>
              <div className="share-container">
                {/* facebook */}
                <div className="share-network-btn">
                  <FacebookShareButton
                    url={shareUrl}
                    className="single-share-network-btn"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                </div>

                {/* email */}
                <div className="share-network-btn">
                  <EmailShareButton
                    url={shareUrl}
                    className="single-share-network-btn"
                  >
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                </div>

                {/* telegram */}
                <div className="share-network-btn">
                  <TelegramShareButton
                    url={shareUrl}
                    className="single-share-network-btn"
                  >
                    <TelegramIcon size={32} round />
                  </TelegramShareButton>
                </div>

                {/* twitter */}
                <div className="share-network-btn">
                  <TwitterShareButton
                    url={shareUrl}
                    className="single-share-network-btn"
                  >
                    <XIcon size={32} round />
                  </TwitterShareButton>
                </div>

                {/* whatsapp */}
                <div className="share-network-btn">
                  <WhatsappShareButton
                    url={shareUrl}
                    className="single-share-network-btn"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="fill-form "
        style={{ display: expanded ? "block" : "none" }}
      >
        <JobForm
          onClose={closeForm}
          department={singleJobPost?.department}
          role={singleJobPost?.role}
        />
      </div>
      {/* </div> */}
      <ToastContainer />
    </>
  );
}
export default SingleJobDetails;

import React from "react";
import "./Box.scss";

const Box = () => {
  return (
    <div>
      <div className="main-box">
        <div className="box-div">
          <div className="top-div">
            <p className="box-heading">Front-End Developer</p>
            <div className="specification">
              <div className="box-1">
                <p className="box-specifications">Fulltime</p>
              </div>
              <div className="box-1">
                <p className="box-specifications">Onsite</p>
              </div>
              <div className="box-1">
                <p className="box-specifications">$200k</p>
              </div>
            </div>
          </div>
         
          <div className="bottom-div">
            <button
            >
              Apply Now
            </button>

            <p className="applied-number">
              <i class="bi bi-people"></i> &nbsp; 24 Applied
            </p>
          </div>
        </div>
        
        <div className="box-background-effect">
          
        </div>
      </div>
    </div>
  );
};

export default Box;

import React from "react";
import "./RevinewFocus.scss";

import icon1 from "../../assets/images/about/revinewFocus/first.png";
import icon2 from "../../assets/images/about/revinewFocus/two.png";

const cardData = [
  {
    TopColor: "#0AA3E9",
    CenterColor: "#26DCFF",
    innerColor: "#A9E9FF",
    icon: icon1,
    head: "Dare to be Different",
    text: "Say goodbye to cookie-cutter solutions and hello to bespoke brilliance. We're not here to blend in. We're here to stand out, crafting unique strategies tailored to your brand's DNA.",
    bottomColor: "#0AA3E9",
    bottomCenter: "#26DCFF",
    bottominner: "#A9E9FF",
  },
  {
    TopColor: "#075EE9",
    CenterColor: "#5983F4",
    innerColor: "#A3B1EC",
    icon: icon2,
    head: "Innovation is our Middle Name",
    text: "Innovation isn't just a buzzword – it's our raison d'être. From AI-powered algorithms to quantum-inspired marketing tactics, we're constantly pushing the boundaries of what's possible in the digital realm.",
    marginTop: "15px",
    bottomColor: "#075EE9",
    bottomCenter: "#5983F4",
    bottominner: "#A3B1EC",
  },
];
export default function RevinewFocus() {
  return (
    <div className="RevinewFocus">
      <h1 className="fucus-head">
        We focus on <span>Revenue</span>.
      </h1>
      <h4 className="it">That’s it</h4>
      <p className="paratexts">
        We craft smart, innovative, creative & handcrafted strategies leveraging
        cutting- <br />
        edge technologies to generate the right leads converting into revenue
        for your <br /> business. We focus on building funnels that target
        people based on the level of <br /> interest, ensuring that every buck
        is appropriately spent where it matters most.
      </p>

      <div className="CardSection">
        {cardData.map((item) => {
          return (
            <div className="card-container" key={item.icon}>
              <img
                src={item.icon}
                alt="icon"
                className="iconImg"
                style={{ marginTop: item.marginTop }}
              />
              <div className="singlecard">
                <div
                  className="topbg"
                  style={{ background: item.TopColor }}
                ></div>
                <div
                  className="centerbg"
                  style={{ background: item.CenterColor }}
                ></div>
                <div
                  className="circle"
                  style={{ background: item.innerColor }}
                ></div>
                {/*  */}

                <h3 className="headings">{item.head}</h3>
                <p className="texts">{item.text}</p>
                <div
                  className="bottomColor"
                  style={{ background: item.bottomColor }}
                ></div>
                <div
                  className="bottomCenter"
                  style={{ background: item.bottomCenter }}
                ></div>
                <div
                  className="bottomInner"
                  style={{ background: item.bottominner }}
                ></div>
              </div>
              <div className="bg1">
                <div className="bg2">
                  <div className="bg3"></div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

import React from "react";
import "./Visioncrafter.scss";

function Visioncrafter() {
  // Step Data with content split where <br /> must appear
  const stepsData = [
    {
      stepNumber: "01",
      heading: "How to become a visioncrafter",
      content: [
        "Discover open positions and find your desired one in ",
        "the eHawkers website, job listings or social media.",
      ],
    },
    {
      stepNumber: "02",
      heading: "Send your application",
      content: [
        "Some simple questions should be answered and your ",
        "contact information is required.",
      ],
    },
    {
      stepNumber: "03",
      heading: "Receive your interview invite",
      content: [
        "We review all applications within 3 working days and ",
        "send invitations to candidates.",
      ],
    },
    {
      stepNumber: "04",
      heading: "Choose an interview slot",
      content: [
        "You will have a friendly discussion with the CEO and ",
        "your supervisor to talk about the work, life, and etc.",
      ],
    },
    {
      stepNumber: "05",
      heading: "Preliminary interview",
      content: [
        "Sometimes, we ask candidates to participate in some",
        "technical challenge that is designated to demonstrate ",
        "candidates’ proficiency.",
      ],
    },
    {
      stepNumber: "06",
      heading: "Meet your teammates",
      content: [
        "To us, it is crucial to make sure all team members feel ",
        "comfortable. It is why we do try to have diverse but ",
        "culturally fitted team members.",
      ],
    },
    {
      stepNumber: "07",
      heading: "Interview with our management",
      content: ["Your colleagues are waiting for you to say a warm welcome."],
    },
  ];

  return (
    
      <div className="vision-main">
        {/* Heading Section */}
        <div className="vision-heading">
          <h1>How to become a visioncrafter</h1>
          <p className="vision-heading-para">
            Joining us couldn't be easier! Check out our application process
            down below. If you have the <br />
            talent we need, then we'll be meeting you soon!
          </p>
        </div>

        {/* Steps Section */}
        <div className="vision-content">
          {/* Background Divs */}
          <div className="top-background-left"></div>
          <div className="top-background-center"></div>
          <div className="top-background-right"></div>

          {/* Dynamic Steps Rendering */}
          <div className="inner-vision-content">
            {stepsData.map((step, index) => (
              <div className="vision-steps" key={index}>
                {/* Alternate layout for step numbers and text */}
                {index % 2 === 0 ? (
                  <>
                    <div className="steps-text">
                      <p className="steps-heading">{step.heading}</p>
                      <p className="steps-content">
                        {step.content.map((line, i) => (
                          <React.Fragment key={i}>
                            {line}
                            {i !== step.content.length - 1 && <br />}
                          </React.Fragment>
                        ))}
                      </p>
                    </div>
                    <div className="step-number-main">
                      <p className="step-number">{step.stepNumber}</p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="step-number-main">
                      <p className="step-number">{step.stepNumber}</p>
                    </div>
                    <div className="steps-text">
                      <p className="steps-heading">{step.heading}</p>
                      <p className="steps-content">
                        {step.content.map((line, i) => (
                          <React.Fragment key={i}>
                            {line}
                            {i !== step.content.length - 1 && <br />}
                          </React.Fragment>
                        ))}
                      </p>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>

          {/* Bottom Background Divs */}
          <div className="bottom-background-left"></div>
          <div className="bottom-background-center"></div>
          <div className="bottom-background-right"></div>
        </div>
        <h1 className="comment">
          Start a new journey!<span> 🎉</span>{" "}
        </h1>
      </div>
    
  );
}

export default Visioncrafter;

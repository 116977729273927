import React, { useState } from "react";
import "./JoinForm.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export default function JoinForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    message: "",
    isSubscribed: true,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateYou = () => {
    // Name validation (minimum 3 characters)
    if (formData.firstName.trim().length < 3) {
      return "First Name should be at least 3 characters long.";
    }

    if (formData.lastName.trim().length < 3) {
      return "Last Name should be at least 3 characters long.";
    }

    // Phone Number validation (must be digits and 10 characters long)
    if (!/^\d{10}$/.test(formData.phoneNumber.trim())) {
      return "Phone number should be 10 digits.";
    }

    // Email validation (must include @ symbol)
    if (!/\S+@\S+\.\S+/.test(formData.email.trim())) {
      return "Email should be valid";
    }

    // Message validation (minimum 3 characters)
    if (formData.message.trim().length < 3) {
      return "Message should be at least 3 characters long.";
    }

    // Return true if no errors, otherwise false
    return "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateYou();
    if (errors.length != 0) {
      toast.warn(errors, {
        position: "bottom-right",
        autoClose: 4000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
      return;
    }

    const config = {
      url: process.env.REACT_APP_BASE_URL + `api/createContact`,
      method: "post",
      data: {
        name: formData?.firstName + " " + formData?.lastName,
        phoneNumber: formData?.phoneNumber,
        email: formData?.email,
        message: formData?.message,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response?.data?.status == true) {
          toast.success("Contact Details Get Successfully", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
          setFormData({
            firstName: "",
            lastName: "",
            phoneNumber: "",
            email: "",
            message: "",
            isSubscribed: true,
          });
        }
        if (response?.data?.status == false) {
          if (response?.data?.response_code == 500) {
            toast.error("Problem With Data Fetching", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          } else if (response?.data?.response_code == 404) {
            toast.error("Problem With Data Fetching", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.response_code == 401) {
          toast.error("Your token is expired. Please login again.", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        } else {
          toast.warn("Problem With Data Fetching", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        }
      });
  };

  const handleCheckboxChange = (event) => {
    setFormData({
      ...formData,
      isSubscribed: event.target.checked,
    });
  };

  return (
    <div className="JoinForm">
      <form className="innerSection" onSubmit={handleSubmit}>
        {/* First Line */}
        <div className="white-bg-color"></div>
        <div className="line">
          <input
            type="text"
            name="firstName"
            placeholder="First name"
            value={formData.firstName}
            onChange={handleChange}
          />
          <input
            type="text"
            name="lastName"
            placeholder="Last name"
            value={formData.lastName}
            onChange={handleChange}
          />
        </div>

        {/* Second Line */}
        <div className="line">
          <input
            type="text"
            name="phoneNumber"
            placeholder="Phone number"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
          <input
            type="email"
            name="email"
            placeholder="Email address"
            value={formData.email}
            onChange={handleChange}
          />
        </div>

        {/* Third Line */}
        <div className="line">
          <input
            type="text"
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
          />
        </div>

        {/* Subscription Option */}
        <div className="subscription">
          <label>
            <input
              type="checkbox"
              className="checkbx"
              checked={formData.isSubscribed}
              onChange={handleCheckboxChange}
            />
            <p style={{fontWeight:"100"}}>I’m happy to subscribe to get updates</p>
          </label>
        </div>

        {/* Submit Button */}
        <div className="CheckBuutton">
          <button type="submit">Connect Now</button>
        </div>
      </form>

      <ToastContainer />
    </div>
  );
}

import React from "react";
import "./TechServiceDetails.scss";
import { FaRegCheckCircle } from "react-icons/fa";
import hoverImage from "../../assets/images/home/fen.png";
// Data for the services
const techServices = [
  {
    number: "01",
    title: "Website <br/> Development",
    items: [
      "Custom website design for specific industries",
      "Portfolio and e-commerce websites",
      "Membership and subscription websites",
    ],
    imagepart: hoverImage,
  },
  {
    number: "02",
    title: "App <br/> Development",
    items: [
      "Enterprise-level mobile apps (iOS, Android)",
      "Educational and gaming apps",
      "Service-on-demand platforms",
    ],
    imagepart: hoverImage,
  },
  {
    number: "03",
    title: "Tech Support <br/> & Maintenance",
    items: [
      "Server management and hosting solutions",
      "Cybersecurity measures and data protection",
      "Performance monitoring and troubleshooting",
    ],
    imagepart: hoverImage,
  },
  {
    number: "04",
    title: "Cloud & <br/> Integration Services",
    items: [
      "CRM integration with sales platforms",
      "Cloud migration for business operations",
      "Custom business dashboards for insights",
    ],
    imagepart: hoverImage,
  },
];

function TechServiceDetails() {
  return (
    <div className="tech-list-main">
      {techServices.map((service) => (
        <div className="single-tech-outer" key={service.number}>
          <div className="single-tech-inner">
            <div className="whitebgoverlay">
              <div className="white-glow-bg"></div>
            </div>
            <div className="tech-list-number">
              <p className="list-single-number">&lt;{service.number}/&gt;</p>
            </div>
            <div className="tech-list-heading">
              <p
                className="single-heading"
                dangerouslySetInnerHTML={{ __html: service.title }}
              ></p>
            </div>
            <div className="hover-overlay">
              <img src={service.imagepart} alt="" className="imageOnHover" />
            </div>
            <div className="tech-list-content">
              {service.items.map((item, index) => (
                <div className="tech-content-inner" key={index}>
                  <FaRegCheckCircle className="circle-icon" />
                  <p className="tech-para">{item}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
export default TechServiceDetails;

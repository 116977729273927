import React from "react";
import "./AboutPage.scss";
import AboutHome from "../../compenents/AboutHome/AboutHome";
import RevinewFocus from "../../compenents/RevinewFocus/RevinewFocus";
import GrowthJourney from "../../compenents/GrowthJourney/GrowthJourney";
import OurExperts from "../../compenents/OurExperts/OurExperts";
import FAQ from "../../compenents/FAQ/FAQ";
import JoinForm from "../../compenents/JoinForm/JoinForm";
import Header from "../../compenents/Header/Header";
import OurPortPolio from "../../compenents/OurPortPolio/OurPortPolio";
import ContactForEvery from "../../compenents/ContactForEvery/ContactForEvery";
import SingleBlogDetails from "../../compenents/SingleBlogDetails/SingleBlogDetails";

export default function AboutPage() {
  return (
    <div className="AboutPage">
      <div className="navheader">
        <Header />
      </div>

      <div className="homeSection">
        <AboutHome />
      </div>

      <div className="secondSection">
        <div className="leftgreenbg"></div>
        <div className="secondgreenbg"></div>
        <div className="centerwhitebg"></div>
        <RevinewFocus />
      </div>

      <div className="thirdSection">
        <GrowthJourney />
      </div>

      <div className="fourthSection">
        <OurExperts />
      </div>

      <div className="fifthSection">
        <div className="leftsectrion">
          <JoinForm />
        </div>
        <div className="rigthSection">
          <FAQ />
        </div>
      </div>

      <div className="sixSection">
        <OurPortPolio />
      </div>

      <div className="joinform">
        <ContactForEvery />
      </div>
    </div>
  );
}

import React from "react";
import "./OurExperts.scss";

import icon1 from "../../assets/images/about/expert/Group4.png";
import icon2 from "../../assets/images/about/expert/Group3.png";
import icon3 from "../../assets/images/about/expert/image2.png";
import icon4 from "../../assets/images/about/expert/image1.png";

export default function OurExperts() {
  return (
    <div className="OurExperts">
      <div className="BgTopBox"></div>
      <div className="BgLeft"></div>
      <div className="BgRight"></div>
      <div className="innerSection">
        <h1>Our Experts</h1>
        <p>
          Our main goal is to work as an extension <br /> of your team, so we’re
          available as much <br /> as you need us. We adapt to whatever <br />{" "}
          work style is best suited for you.
        </p>
        <div>
          <div className="icon4">
            <img src={icon1} alt="" />
          </div>
          <div className="icon3">
            <img src={icon2} alt="" />
          </div>
          <div className="icon2">
            <img src={icon3} alt="" />
          </div>
          <div className="icon1">
            <img src={icon4} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import JoinForm from "../JoinForm/JoinForm";
import "./ContactForEvery.scss";
import QR from "../../assets/images/E-Hawkers_QR.png";

function ContactForEvery() {
  return (
    <div className="mainContactFormForEvery">
      <div className="MessageOfContact">
        <p className="letstalk">
          {" "}
          Let’s talk <span>business.</span> <br /> But you first.
        </p>
        <div className="QR-code">
          <img src={QR} alt="" />
        </div>
      </div>

      <div className="ContactForm">
        <JoinForm />
      </div>
    </div>
  );
}

export default ContactForEvery;

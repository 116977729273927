import React from "react";
import "./Performance.scss";
// import gif from "../../../assets/images/marketing service/performance/gif.gif";
import gif1 from "../../../assets/images/marketing service/performance/ss.gif";

const PerformanceData = [
  {
    title: "LinkedIn B2B Ads",
    description:
      "Looking to reach a B2B audience? Try LinkedIn B2B advertising! Our experts will help you gain the exposure you want while helping you build the long-term relationships you need to succeed in the B2B world.",
    small: "#beb7aa",
    center: "#796d91",
    bigbg: "#ad57a0",
  },
  {
    title: "Display Ads",
    description:
      "We create custom, attention-grabbing visuals paired with high-quality placements. Our in-house designers work hand-in-hand with our pay-per-click team to make each ad unique and conversion-driven.",
    small: "#FF5E78",
    center: "#CF4158",
    bigbg: "#61256E",
  },
  {
    title: "Meta Ads",
    description:
      "Facebook, Instagram, and WhatsApp ads are always evolving. As a Meta Certified Company, our team is always on top of the changes. We know what is necessary to boost your brand’s visibility and will reach audiences you didn't know you had.",
    small: "#DFE4E4",
    center: "#32C2E0",
    bigbg: "#3337A6",
  },
  {
    title: "Google Ads",
    description:
      "We deliver high-value traffic sculpting within Google Ads, all the while focusing on precise intent-targeting to make sure that each ad is being placed in front of the right audiences. eHawkers has in-house content writers and creative creators who create conversion-driven, custom copy to drive home results.",
    small: "#FCC937",
    center: "#F98036",
    bigbg: "#F08274",
  },
  {
    title: "Remarketing",
    description:
      "If you're interested in revenue, you’re interested in remarketing campaigns. We reach high-intent audiences with precise audience segmentation to gain back or follow up with existing interested users.",
    small: "#DEB7AA",
    center: "#796D91",
    bigbg: "#AD57A0",
  },
];

export default function Performance() {
  const getPositionClass = (index) => {
    switch (index) {
      case 0:
        return "left-1";
      case 1:
        return "left-0";
      case 2:
        return "right-0";
      case 3:
        return "left-2";
      case 4:
        return "center";
      default:
        return "";
    }
  };

  return (
    <div className="Performance">
      <h1 className="Performance-head">
        Performance Marketing: <br /> Driving results, Maximizing{" "}
        <span>Revenue</span>
      </h1>
      <p className="performance-para">
        Experience unparalleled growth with data-driven performance marketing.
        We optimize your campaigns <br /> to deliver measurable results,
        ensuring every dollar spent brings you a substantial return on
        investment.
      </p>
      <div className="card-container-parent">
        <div className="colorgif"></div>
        {/* <img src={gif1} alt="" className="colorgif" /> */}
        <div className="Performance-cards">
          {PerformanceData.map((item, index) => (
            <div className="Performance-card" key={index}>
              <h2 className="item-heading">{item.title}</h2>
              <p className="item-des">{item.description}</p>
              <div className={`itembg ${getPositionClass(index)}`}>
                <div
                  className="itembg1"
                  style={{ background: item.bigbg }}
                ></div>
                <div
                  className="itembg2"
                  style={{ background: item.center }}
                ></div>
                <div
                  className="itembg3"
                  style={{ background: item.small }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import "./AllBlogs.scss";
import { blogData } from "../../Constants/BlogData";

import img1 from "../../assets/images/Blogs/bloghome/img1.png";
import img2 from "../../assets/images/Blogs/bloghome/img2.png";
import img3 from "../../assets/images/Blogs/bloghome/img3.png";
import { useNavigate } from "react-router-dom";

const allBlogdata = [
  {
    category: "Technology",
    img: img1,
    titleName: "What is supervised machine learning?",
    autherName: "aryan patel",
    date: "12-dec-2022",
  },
  {
    category: "Technology",
    img: img2,
    titleName: "What is supervised machine learning?",
    autherName: "aryan patel",
    date: "12-dec-2022",
  },
  {
    category: "Technology",
    img: img3,
    titleName: "What is supervised machine learning?",
    autherName: "aryan patel",
    date: "12-dec-2022",
  },
  {
    category: "Technology",
    img: img3,
    titleName: "What is supervised machine learning?",
    autherName: "aryan patel",
    date: "12-dec-2022",
  },
  {
    category: "Media",
    img: img3,
    titleName: "What is supervised machine learning?",
    autherName: "aryan patel",
    date: "12-dec-2022",
  },
  {
    category: "Media",

    img: img3,
    titleName: "What is supervised machine learning?",
    autherName: "aryan patel",
    date: "12-dec-2022",
  },
];

const blogHeadings = [
  "All",
  "Technology",
  "Media",
  "Brand",
  "Marketing",
  "Strategy",
];

export default function AllBlogs() {
  const navigate = useNavigate();

  const handleProductClick = (blog) => {
    navigate(`/blog/${blog.id}`, { state: blog });
  };
  const [activeTab, setActiveTab] = useState("All");
  // Filtered data to display based on activeTab
  const filteredData =
    activeTab === "All"
      ? blogData // Show all images if no category is active
      : blogData.filter((item) => item.category === activeTab);

  const handleTabClick = (heading) => {
    setActiveTab((prevTab) => (prevTab === heading ? "All" : heading));
  };

  return (
    <div className="AllBlogs">
      <div className="news-event">
        <h1 className="news-heading">Blogs</h1>
        <div className="news-line"></div>
      </div>
      <div className="allblog-section">
        <div className="Blogs-Header">
          <ul>
            {blogHeadings.map((heading) => (
              <li
                key={heading}
                onClick={() => handleTabClick(heading)}
                className={activeTab === heading ? "active" : ""}
              >
                {heading}
              </li>
            ))}
          </ul>
        </div>
        <div className="allblog-card">
          <div className="allcard-inner">
            {filteredData.map((blog, id) => {
              return (
                <div
                  className="allblog-single-card cursor-pointer"
                  key={id}
                  onClick={() => handleProductClick(blog)}
                >
                  <img src={blog.img} alt={blog.titleName} />
                  <div className="allblog-text">
                    <h3 className="allblog-title">{blog.titleName}</h3>
                    <div className="allauther-container d-flex jusctify-content-between w-100">
                      <p className="allauther">{blog.autherName}</p>
                      <p className="alldate ">{blog.date}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import "./Joblistings.scss";
import PostJobBox from "../PostJobBox/PostJobBox";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function Joblistings() {
  const [jobsList, setJobsList] = useState([
    {
      _id: "1",
      position: "Senior Developer",
      role: "Full Stack Developer",
      lowSalary: "100",
    },
    {
      _id: "2",
      position: "Mid-level Developer",
      role: "Frontend Developer",
      lowSalary: "800",
    },
    {
      _id: "3",
      position: "Software Engineer",
      role: "Backend Developer",
      lowSalary: "90",
    },
    {
      _id: "4",
      position: "Graphic Designer",
      role: "Graphic Designer",
      lowSalary: "120",
    },
  ]);
  const [activeCategory, setActiveCategory] = useState("All");
  const [filteredJobs, setFilteredJobs] = useState(jobsList);

  const filterJobs = (category) => {
    setActiveCategory(category);
    console.log(category);
    if (category === "All") {
      setFilteredJobs(jobsList);
      setFilteredJobs(jobsList);
    } else {
      setFilteredJobs(jobsList.filter((job) => job.role === category)); // Filter jobs by role
    }
  };

  const getAllJobPost = () => {
    const config = {
      url: process.env.REACT_APP_BASE_URL + "api/getAllJobPosts",
      method: "get",
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Hello");
        console.log(response?.data);
        if (response?.data?.status == true) {
          console.log("dfghj", response?.data);
          setJobsList(response?.data?.data);
          setFilteredJobs(response?.data?.data);
        }
        if (response?.data?.status == false) {
          if (response?.data?.response_code == 500) {
            toast.error("Problem With Data Fetching", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          } else if (response?.data?.response_code == 404) {
            toast.error("Problem With Data Fetching", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.response_code == 401) {
          toast.error("Your token is expired. Please login again.", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        } else {
          toast.warn("Problem With Data Fetching", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        }
      });
  };

  useEffect(() => {
    getAllJobPost();
  }, []);

  return (
    <div className="fourth-div" id="AllJob" data-popup="Apply your dream job">
      <div className="listing-heading">Job Listings</div>

      <h1 className="text-center bolt-5 heading-4">
        Interested in a future <br />
        at eHawkers Marketing
      </h1>
      {/* <div className="para-4 text-center"></div> */}

      <div className="Navbar" style={{ zIndex: 100 }}>
        <h5
          className={`nava ${
            activeCategory === "All" ? "active-border" : "inactive-border"
          }`}
          onClick={() => filterJobs("All")}
        >
          All Recent
        </h5>
        <h5
          className={`nava ${
            activeCategory === "Graphic Designer"
              ? "active-border"
              : "inactive-border"
          }`}
          onClick={() => filterJobs("Graphic Designer")}
        >
          Graphic Designer
        </h5>
        <h5
          className={`nava ${
            activeCategory === "Digital Marketing Executive"
              ? "active-border"
              : "inactive-border"
          }`}
          onClick={() => filterJobs("Digital Marketing Executive")}
        >
          Marketing
        </h5>
        <h5
          className={`nava ${
            activeCategory === "Backend Developer"
              ? "active-border"
              : "inactive-border"
          }`}
          onClick={() => filterJobs("Backend Developer")}
        >
          Backend Developer
        </h5>
        <h5
          className={`nava ${
            activeCategory === "Frontend Developer"
              ? "active-border"
              : "inactive-border"
          }`}
          onClick={() => filterJobs("Frontend Developer")}
        >
          Frontend Developer
        </h5>
      </div>

      <div className="job-post-box">
        <div className="jobpost">
          {filteredJobs.map((item) => (
            <PostJobBox key={item._id} item={item} />
          ))}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Joblistings;

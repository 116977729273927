import React from "react";
import "./CareerHeroSection.scss";
import Box from "../Box/Box";

function CareerHeroSection() {
  return (
    <div className="first-div" data-popup="start your career">
      <div className="CareeerInnerSection">
        <div className="cc">
          <Box />
        </div>
        <h1 className="career-hero-header">One Step Closer To</h1>
        <h1 className="heading-2">
          Your <span className="special-words">Dream Careers</span>
        </h1>
        <p className="text-center para-top">
          We're looking for great people to join our growing team. &nbsp;{" "}
          <a href="#AllJob" className="apply-btn">
            {" "}
            Apply now!
          </a>
        </p>
        <div className="box1">
          <Box />
        </div>

        <div className="box3">
          <Box />
        </div>
      </div>

      <div className="background-effect"></div>
    </div>
  );
}

export default CareerHeroSection;

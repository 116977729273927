import React, { useState } from "react";
import "./HomeService.scss";

// images
import brand from "../../assets/images/home/serviceCmnt/brand.svg";
import hourse from "../../assets/images/home/serviceCmnt/godha.png";
import code from "../../assets/images/home/serviceCmnt/code.png";
import market from "../../assets/images/home/serviceCmnt/increase.png";
import videoicon from "../../assets/images/home/serviceCmnt/vicon.png";
// hover icons...
import brandHover from "../../assets/images/home/serviceCmnt/arrow.png";

import markethover from "../../assets/images/home/service/market.svg";
import mediahover from "../../assets/images/home/service/media.svg";
import strategyhover from "../../assets/images/home/service/strategy.svg";
import techhover from "../../assets/images/home/service/tech.svg";

import video01 from "../../assets/images/home/service/01.mp4";
import video02 from "../../assets/images/home/service/02.mp4";
import video03 from "../../assets/images/home/service/03.mp4";
import video04 from "../../assets/images/home/service/04.mp4";
import video05 from "../../assets/images/home/service/05.mp4";
import { useNavigate } from "react-router-dom";

const servicesData = [
  {
    id: 1,
    icon: market,
    iconHover: markethover,
    serviceName: "Marketing Solutions",
    heading: "Marketing Solutions",
    position: ["Strategy", "Analysis", "Planning"],
    details:
      "Stay ahead of the competition with strategic marketing solutions. We provide insights, analysis, and plans that help you achieve your goals efficiently while connecting with your audience meaningfully.",
    media: [
      {
        icon: "bi bi-graph-up",
        heading: "Analyze",
        rightIcon: "bi bi-arrow-right",
      },
      {
        icon: "bi bi-clipboard2-check",
        heading: "Strategy",
        rightIcon: "bi bi-arrow-right",
      },
    ],
    onclickPart: "/marketing-service",
    video: video05,
    colorCode: "#ffff007F",
  },
  {
    id: 2,
    icon: videoicon,
    iconHover: mediahover,

    serviceName: "Media Solutions",
    heading: "Media Solutions",
    position: ["Web", "Mobile", "E-commerce"],
    details:
      "Maximize your online presence with tailored media solutions. From designing responsive websites to developing robust mobile apps, we ensure your brand reaches and resonates with the right audience.",
    media: [
      {
        icon: "bi bi-laptop",

        heading: "Web Solutions",
        rightIcon: "bi bi-arrow-right",
      },
      {
        icon: "bi bi-phone",
        heading: "Mobile Apps",
        rightIcon: "bi bi-arrow-right",
      },
    ],
    onclickPart: "/MediaService",
    video: video02,
    colorCode: "#ff796e7f",
  },

  {
    id: 3,
    icon: code,

    iconHover: techhover,
    serviceName: "Tech Solutions",
    heading: "Tech Solutions",
    position: ["Innovation", "Efficiency", "Planning"],
    details:
      "Leverage technology to drive innovation and efficiency. Our tech solutions cover strategy, analysis, and planning to ensure your project runs seamlessly and achieves success.",
    media: [
      {
        icon: "bi bi-graph-up",
        heading: "Analyze",
        rightIcon: "bi bi-arrow-right",
      },
      {
        icon: "bi bi-clipboard2-check",
        heading: "Strategy",
        rightIcon: "bi bi-arrow-right",
      },
    ],
    onclickPart: "/techservice",
    video: video04,
    colorCode: "#4285f47f",
  },
  {
    id: 4,
    icon: brand,
    iconHover: brandHover,
    serviceName: "Brand Solutions",
    position: ["Identity", "Messaging", "Campaigns"],
    heading: "Brand Solutions",
    details:
      "Your brand is the foundation of your business. It creates a connection, builds trust, and establishes a lasting impression. Our services empower you to define your voice, refine your image, and captivate your audience with purpose.",
    media: [
      {
        icon: "bi bi-camera-fill",
        heading: "Visual Content",
        rightIcon: "bi bi-arrow-right",
      },
      {
        icon: "bi bi-mic-fill",
        heading: "Audio Production",
        rightIcon: "bi bi-arrow-right",
      },
    ],
    onclickPart: "/brand",
    video: video03,
    colorCode: "#5de18b7f",
  },

  {
    id: 5,
    icon: hourse,
    iconHover: strategyhover,
    colorCode: "black",
    serviceName: "Strategy Solutions",
    heading: "Strategy Solutions",
    position: ["Growth", "Planning", "Execution"],
    details:
      "Every successful business begins with a strong strategy. We assist you in planning and executing strategies that align with your goals, enabling sustainable growth and long-term success.",
    media: [
      {
        icon: "bi bi-graph-up",
        heading: "Analyze",
        rightIcon: "bi bi-arrow-right",
      },
      {
        icon: "bi bi-clipboard2-check",
        heading: "Strategy",
        rightIcon: "bi bi-arrow-right",
      },
    ],
    onclickPart: "/strategy-service",
    video: video01,
  },
];

export default function HomeService() {
  const [selectedService, setSelectedService] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const navigate = useNavigate();

  return (
    <div className="HomeService">
      <div className="Headingbtn">Our Service</div>
      <h1 className="heading">Why do I need eHawkers</h1>
      <p className="para">
        Crafting Brands, Driving Success, Comprehensive Marketing and Technical
        Solutions <br /> for Lasting Online Relationships and Revenue Growth
      </p>

      <div className="brandSolution">
        <div className="information">
          <div className="accordion" id="accordionExample">
            {servicesData.map((item, index) => (
              <div
                className={`accordion-item mainContainer ${
                  activeIndex === index ? "active" : ""
                }`}
                key={item.id}
              >
                {activeIndex === index ? (
                  <img
                    src={item.iconHover}
                    alt="icon"
                    className="checkimg"
                    style={{ width: "20px", height: "20px" }}
                  />
                ) : (
                  <img
                    src={item.icon}
                    alt="icon"
                    className="checkimg"
                    style={{ width: "20px", height: "20px" }}
                  />
                )}

                <div
                  className="textSection"
                  onMouseEnter={() => {
                    setActiveIndex(index);
                    setSelectedService(index);
                  }}
                >
                  <div className="headingIcon">
                    <div className="icon">
                      <h4 className="accordion-header" id={`heading${item.id}`}>
                        <button
                          className={`accordion ${
                            activeIndex === index ? "" : "collapsed"
                          } bi-arrow-rights faright`}
                          type="button"
                          aria-expanded={
                            activeIndex === index ? "true" : "false"
                          }
                          aria-controls={`collapse${item.id}`}
                        >
                          {item?.serviceName}{" "}
                          {activeIndex === index ? (
                            <div
                              className="Circle_around_arrow"
                              style={
                                {
                                  // backgroundColor: "white",
                                }
                              }
                            >
                              <span
                                className="bi bi-arrow-right text-end"
                                onClick={(event) => {
                                  if (
                                    event.ctrlKey ||
                                    event.metaKey ||
                                    event.button === 1
                                  ) {
                                    // If Ctrl (or Command on macOS) or middle mouse button is clicked

                                    const newTabUrl = item?.onclickPart;
                                    window.open(newTabUrl, "_blank");
                                  } else {
                                    // Default behavior for normal left-click
                                    window.scrollTo(0, 0);
                                    navigate(item?.onclickPart);
                                  }
                                }}
                                style={{
                                  color: item?.colorCode,
                                }}
                              ></span>
                            </div>
                          ) : (
                            <span
                              className="bi bi-arrow-right text-end"
                              onClick={(event) => {
                                if (
                                  event.ctrlKey ||
                                  event.metaKey ||
                                  event.button === 1
                                ) {
                                  const newTabUrl = item?.onclickPart;
                                  window.open(newTabUrl, "_blank");
                                } else {
                                  window.scrollTo(0, 0);
                                  navigate(item?.onclickPart);
                                }
                              }}
                              style={{
                                color: "white",
                              }}
                            ></span>
                          )}
                        </button>
                      </h4>
                    </div>
                  </div>
                  <div
                    id={`collapse${item.id}`}
                    className={`accordion-collapse collapse ${
                      activeIndex === index ? "show" : ""
                    }`}
                    aria-labelledby={`heading${item.id}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="position-section">
                      {item?.position.map((text, id) => (
                        <p key={id} className="text-inf">
                          {text}
                        </p>
                      ))}
                    </div>
                    <div className="details">{item?.details}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="images">
          {servicesData[selectedService]?.video ? (
            <video
              autoPlay
              loop
              muted
              className="video"
              key={servicesData[selectedService]?.video}
              style={{
                boxShadow: `0px 30px 140px ${servicesData[selectedService]?.colorCode}`,
              }}
            >
              <source
                src={servicesData[selectedService]?.video}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          ) : (
            <p>No video available for this service.</p>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import "./brandIceberge.scss";
import gsap from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import ice from "../../../assets/images/brandPage/ice.png";
import check from "../../../assets/images/Blogs/bloghome/img1.png";
gsap.registerPlugin(ScrollTrigger);

export default function BrandIceberge() {
  const iceHeadingRef = useRef(null);
  const iceBergeSectionRef = useRef(null);
  gsap.registerPlugin(ScrollTrigger);

  let scrollCount = 0;

  window.addEventListener("wheel", (e) => {
    const innerBox = document.querySelector(".ice-berge-section");

    if (e.deltaY > 0) {
      // Scroll Down
      scrollCount++;
    } else {
      // Scroll Up
      scrollCount--;
    }

    if (scrollCount % 2 === 0) {
      innerBox.style.top = "50%"; // Show 50% from the top
    } else {
      innerBox.style.top = "calc(100% - 500px)"; // Show 50% from the bottom
    }
  });

  useEffect(() => {
    const iceHeading = iceHeadingRef.current;
    gsap.to(iceHeading, {
      scrollTrigger: {
        trigger: iceHeading,
        start: "top 10%",
        end: "top 55%",
        scrub: true,
      },
      filter: "blur(10px)",
    });
  }, []);

  useEffect(() => {
    const section = iceBergeSectionRef.current;
    const iceBg = section.querySelector(".ice-bg");
    const blurOverlay = section.querySelector(".blur-overlay");
    const icee = section.querySelector(".icee");
    // const fullline = section.querySelector(".fullline-ouverlay");
    // const linecontainer = section.querySelector(".linecontainer");
    // icee.style.transition = "all 0.9s ease";
    // linecontainer.style.transition = "all 0.9s ease";

    // gsap.set(linecontainer, {
    //   position: "relative",
    // });

    gsap.to(icee, {
      x: "-15%",
      y: "-5%",
      opacity: 0.8,
      scrollTrigger: {
        trigger: section,
        start: "10.50% ",
        end: " top",
        scrub: 1,
      },
    });

    gsap.to(iceBg, {
      zIndex: "-1",
      scrollTrigger: {
        trigger: section,
        start: "10.50% ",
        end: " top",
        scrub: true,
      },
    });

    gsap.to(blurOverlay, {
      display: "inline-block",
      filter: "blur(20px)",
      zIndex: "10000000",
      scrollTrigger: {
        trigger: section,
        start: "10% top",
        end: "bottom top",
        scrub: true,
      },
    });
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  const IceBergList = [
    {
      name: "Logo C",
      details: [
        {
          img: "Check",
          Titla: "Name & Strapline",
          detaisl:
            "Curabitur mattis ante et viverra vestibulum. Ut libero massa, iaculis vitae mattis et, efficitur non nisl.",
        },
      ],
      desction:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, esse. Veritatis, provident beatae sapiente labore in nisi deleniti voluptatibus repellat.",
    },
    {
      name: "Visual Identity",
      desction:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, esse. Veritatis, provident beatae sapiente labore in nisi deleniti voluptatibus repellat.",
      details: [
        {
          img: "Check",
          Titla: "Name & Strapline",
          detaisl:
            "Curabitur mattis ante et viverra vestibulum. Ut libero massa, iaculis vitae mattis et, efficitur non nisl.",
        },
        {
          img: "Check",
          Titla: "Name & Strapline",
          detaisl:
            "Curabitur mattis ante et viverra vestibulum. Ut libero massa, iaculis vitae mattis et, efficitur non nisl.",
        },
      ],
    },
    {
      name: "How to communicate it",
      desction:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, esse. Veritatis, provident beatae sapiente labore in nisi deleniti voluptatibus repellat.",
      details: [
        {
          img: "Check",
          Titla: "Name & Strapline",
          detaisl:
            "Curabitur mattis ante et viverra vestibulum. Ut libero massa, iaculis vitae mattis et, efficitur non nisl.",
        },
        {
          img: "Check",
          Titla: "Name & Strapline",
          detaisl:
            "Curabitur mattis ante et viverra vestibulum. Ut libero massa, iaculis vitae mattis et, efficitur non nisl.",
        },
      ],
    },
    {
      name: "How you did it",
      desction:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, esse. Veritatis, provident beatae sapiente labore in nisi deleniti voluptatibus repellat.",
      details: [
        {
          img: "Check",
          Titla: "Name & Strapline",
          detaisl:
            "Curabitur mattis ante et viverra vestibulum. Ut libero massa, iaculis vitae mattis et, efficitur non nisl.",
        },
        {
          img: "Check",
          Titla: "Name & Strapline",
          detaisl:
            "Curabitur mattis ante et viverra vestibulum. Ut libero massa, iaculis vitae mattis et, efficitur non nisl.",
        },
      ],
    },
    {
      name: "What you do",
      desction:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, esse. Veritatis, provident beatae sapiente labore in nisi deleniti voluptatibus repellat.",
      details: [
        {
          img: "Check",
          Titla: "Name & Strapline",
          detaisl:
            "Curabitur mattis ante et viverra vestibulum. Ut libero massa, iaculis vitae mattis et, efficitur non nisl.",
        },
        {
          img: "Check",
          Titla: "Name & Strapline",
          detaisl:
            "Curabitur mattis ante et viverra vestibulum. Ut libero massa, iaculis vitae mattis et, efficitur non nisl.",
        },
      ],
    },
    {
      name: "Who are you",
      desction:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, esse. Veritatis, provident beatae sapiente labore in nisi deleniti voluptatibus repellat.",
      details: [
        {
          img: "Check",
          Titla: "Name & Strapline",
          detaisl:
            "Curabitur mattis ante et viverra vestibulum. Ut libero massa, iaculis vitae mattis et, efficitur non nisl.",
        },
        {
          img: "Check",
          Titla: "Name & Strapline",
          detaisl:
            "Curabitur mattis ante et viverra vestibulum. Ut libero massa, iaculis vitae mattis et, efficitur non nisl.",
        },
      ],
    },
  ];

  const [displaycheck, setDisplayCheck] = useState("none");
  const [valueForTop, setValueForTop] = useState(0);
  const [categoryToShow, setCategoryToShow] = useState("Who are you");

  const setAllOther = (value, index) => {
    console.log(value);
    setValueForTop(index);
    setDisplayCheck((prev) => (prev === "none" ? "inline" : "none"));
    setCategoryToShow(value);
  };

  const lineItems = [
    { text: "Logo", value: "Logo C", indexValue: 2 },
    { text: "Visual Identity", value: "Visual Identity", indexValue: 10 },
    {
      text: "How to communicate it",
      value: "How to communicate it",
      indexValue: 15,
    },
    { text: "How you did it", value: "How you did it", indexValue: 20 },
    { text: "What you do", value: "What you do", indexValue: 25 },
    { text: "Who are you", value: "Who are you", indexValue: 30 },
  ];
  // useEffect(() => {
  //   gsap.to(".fullline", {
  //     x: "-=2000%",
  //     y: "-6%",
  //     duration: 20,
  //     ease: "none",
  //     repeat: 1,
  //     marker: true,
  //     transition: "all 0.9s ease",
  //     scrollTrigger: {
  //       // trigger: ".fullline",
  //       start: "17.60% ",
  //       end: "top",
  //       scrub: true,
  //     },
  //   });
  // }, []);
  useEffect(() => {
    gsap.to(".linecontainer", {
      x: "-38%",
      y: "-5.50%",
      // transition: "all 0.9s ease",
      // duration: 8,
      scrollTrigger: {
        trigger: ".lineContainer",
        start: "17.50% ",
        end: " top",
        scrub: 1,
      },
    });
  });

  return (
    <div className="brandIceberge">
      {/* Ice Heading Section */}
      <div
        className="ice-heading d-flex justify-content-between"
        // ref={iceHeadingRef}
      >
        <div className="left-content">
          <h1 className="elevate">Elevate your business</h1>
          <h1 className="elevate">into brands</h1>
        </div>
        <div className="right-content">
          <p className="brand-paragraph text-end">
            Elevate your brand’s presence with compelling visuals <br /> and
            storytelling. We design unique brand identities that <br />
            captivate and leave a lasting impression, positioning <br /> you as
            a leader in your industry.
          </p>
        </div>
      </div>

      {/* Ice Berge Section */}
      <div
        className="ss"
        style={{
          display: "flex",
          width: "100%",
          overflowY: "auto",
          overflowX: "none",
          margin: "auto",
        }}
      >
        <div
          className={`brandIcebergeChewck ${
            displaycheck !== "none" ? "move-left" : ""
          }`}
        >
          <div className="ice-berge-section" ref={iceBergeSectionRef}>
            <h6 className="ice-you-heading">You know the tip of the</h6>
            <h1 className="ICEBERG">ICEBERG</h1>
            <div className="ice-image">
              <img src={ice} alt="iceberge" className="icee" />
            </div>
            {/* <div className="fullline-ouverlay"> */}
            {/* <div className="full-line fullline"></div> */}
            {/* </div> */}
            <div className="blur-overlay"></div>
            <div className="ice-bg"></div>
          </div>

          {/* <div className="linecontainer0verlay"> */}
          <div className="lines-container  linecontainer">
            {lineItems.map((item, index) => (
              <div className="line-item" key={index}>
                <div className="horizontal-line"></div>
                <button
                  className="text"
                  onClick={() => setAllOther(item.value, item.indexValue)}
                >
                  {item.text}
                </button>
              </div>
            ))}
          </div>
          {/* </div> */}

          <div
            className={`showOtherDetails ${
              displaycheck !== "none" ? "moveLeft" : ""
            }`}
            style={{ top: `${26 + valueForTop}%` }}
          >
            <div className="arryaPartOfice">
              {IceBergList.map((item, index) => {
                const { name, details, desction } = item;
                if (name === categoryToShow) {
                  return (
                    <div key={index} className="category-container">
                      <div className="TopPartOflefticeburg">
                        <h2>{name}</h2>
                        <p>{desction}</p>
                      </div>
                      {details.map((innerItem, i) => (
                        <div key={i} className="item-box">
                          <div className="img-container">
                            <img src={check} alt="Logo" className="img" />
                          </div>
                          <div className="text-content">
                            <div className="TitleOfList">{innerItem.Titla}</div>
                            <div className="details">
                              <p>{innerItem.detaisl}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

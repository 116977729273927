import React, { useEffect } from "react";
import "./Firewall.scss";
import bgimg from "../../../assets/images/about/bgimg.png";

import effect1 from "../../../assets/images/marketing service/meteor.png";
import earth from "../../../assets/images/marketing service/earth.png";

import effect2 from "../../../assets/images/marketing service/meteor (1).png";
import effect3 from "../../../assets/images/marketing service/meteor (2).png";
import effect4 from "../../../assets/images/marketing service/meteor (3).png";

export default function Firewall() {
  useEffect(() => {
    const hoverCircle = document.querySelector(".hover-circle");
    const overlay = document.querySelector(".oner-overlay");

    const handleMouseMove = (e) => {
      const rect = overlay.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      hoverCircle.style.left = `${x}px`;
      hoverCircle.style.top = `${y}px`;

      
      const fires = document.querySelectorAll(".fire");
      fires.forEach((fire) => {
        const fireRect = fire.getBoundingClientRect();
        const fireCenterX = fireRect.left + fireRect.width / 2 - rect.left;
        const fireCenterY = fireRect.top + fireRect.height / 2 - rect.top;

        const distance = Math.sqrt(
          Math.pow(fireCenterX - x, 2) + Math.pow(fireCenterY - y, 2)
        );

        if (distance < 150) {
          fire.style.opacity = "1"; 
          fire.querySelector("marquee").style.opacity = "1";
        } else {
          fire.style.opacity = "0";
          fire.querySelector("marquee").style.opacity = "0"; 
        }
      });
    };

    overlay.addEventListener("mousemove", handleMouseMove);

    return () => {
      overlay.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  return (
    <div className="Firewall">
      <div className="top-info">
        <div className="left-firewall-content">
          <div className="f-head">Digital Marketing:</div>
          <div className="f-head">Transforming Clicks </div>
          <div className="f-head">
            into <span>Cashes</span>
          </div>
        </div>
        <div className="right-firewall-content">
          <p className="fire-paragraph">
            Unlock your brand’s potential with strategic digital <br />{" "}
            marketing. With our expertise, every click brings you <br /> closer
            to your goals.
          </p>
        </div>
      </div>
      <div className="firewall-section">
        <img src={earth} alt="" className="bgfireimg" />
        <img src={bgimg} alt="" className="bgfireimg-for-mobile" />
        <div className="fires-section">
          <div className="oner-overlay">
            <div className="fire fire1">
              <marquee scrollamount="10" scrolldelay="100" className="marquee">
                <img src={effect1} alt="" /> Customer Acquisition
              </marquee>
            </div>
            <div className="fire fire2">
              <marquee scrollamount="10" scrolldelay="100" className="marquee">
                <img src={effect1} alt="" /> Market Expansion
              </marquee>
            </div>
            <div className="fire fire3">
              <marquee scrollamount="10" scrolldelay="100" className="marquee">
                <img src={effect1} alt="" /> Audience Engagement
              </marquee>
            </div>
            <div className="fire fire4">
              <marquee scrollamount="10" scrolldelay="100" className="marquee">
                <img src={effect1} alt="" /> Audience Engagement
              </marquee>
            </div>
            <div className="fire fire5">
              <marquee scrollamount="10" scrolldelay="100" className="marquee">
                <img src={effect1} alt="" /> Customer Acquisition
              </marquee>
            </div>
            <div className="fire fire6">
              <marquee scrollamount="10" scrolldelay="100" className="marquee">
                <img src={effect1} alt="" /> Market Expansion
              </marquee>
            </div>
            <div className="fire fire7">
              <marquee scrollamount="10" scrolldelay="100" className="marquee">
                <img src={effect1} alt="" /> Audience Engagement
              </marquee>
            </div>
            <div className="fire fire8">
              <marquee scrollamount="10" scrolldelay="100" className="marquee">
                <img src={effect1} alt="" /> Audience Engagement
                {/* <img src={effect1} alt="" /> Audience Engagement */}
              </marquee>
            </div>
          </div>

          <div className="hover-circle"></div>
        </div>

        <div className="fire-img-info">
          <h1 className="ignite">
            Ignite your brand with <br /> meteoric impact
          </h1>
          <p className="boost">
            Boost Your Sales Team | Generate Leads That Convert | Build Your
            Brand
          </p>
        </div>
      </div>
    </div>
  );
}

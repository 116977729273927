import React, { useEffect, useState } from "react";
import "./Ourclient.scss";
// import {logos} from "../../Constants/Clientlogose";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import gsap from "gsap";

// logos
import logo1 from "../../assets/images/home/clientlogos/akash1.png";
import logo2 from "../../assets/images/home/clientlogos/aqua1.png";
import logo3 from "../../assets/images/home/clientlogos/asia1.png";
import logo4 from "../../assets/images/home/clientlogos/aurbo1.png";
import logo5 from "../../assets/images/home/clientlogos/centurynova1.png";
import logo6 from "../../assets/images/home/clientlogos/chaouhan roadways1.png";
import logo7 from "../../assets/images/home/clientlogos/divyta1.png";
import logo8 from "../../assets/images/home/clientlogos/ehawker1.png";
import logo9 from "../../assets/images/home/clientlogos/propertysuchna1.png";
import logo10 from "../../assets/images/home/clientlogos/eslsteel1.png";
import logo12 from "../../assets/images/home/clientlogos/flyingrabit1.png";
import logo13 from "../../assets/images/home/clientlogos/greenads1.png";
import logo14 from "../../assets/images/home/clientlogos/grow1.png";
import logo15 from "../../assets/images/home/clientlogos/hrscoop1.png";
import logo16 from "../../assets/images/home/clientlogos/iimsambal1.png";
import logo17 from "../../assets/images/home/clientlogos/industries1.png";
import logo18 from "../../assets/images/home/clientlogos/isheros1.png";
import logo19 from "../../assets/images/home/clientlogos/javedhabib1.png";
import logo20 from "../../assets/images/home/clientlogos/jettrades1.png";
import logo21 from "../../assets/images/home/clientlogos/kanha1.png";
import logo22 from "../../assets/images/home/clientlogos/phonepe1.png";
import logo23 from "../../assets/images/home/clientlogos/raer1.png";
import logo24 from "../../assets/images/home/clientlogos/rafu1.png";
import logo25 from "../../assets/images/home/clientlogos/revoral1.png";
import logo26 from "../../assets/images/home/clientlogos/shiprocket1.png";
import logo27 from "../../assets/images/home/clientlogos/shreeji1.png";
import logo28 from "../../assets/images/home/clientlogos/src1.png";
import logo29 from "../../assets/images/home/clientlogos/tafi1.png";
import logo30 from "../../assets/images/home/clientlogos/tempus1.png";
import logo31 from "../../assets/images/home/clientlogos/thinkingbridge1.png";
import logo32 from "../../assets/images/home/clientlogos/unipride1.png";
import logo33 from "../../assets/images/home/clientlogos/vedanta1.png";
import logo34 from "../../assets/images/home/clientlogos/yonfetti1.png";

import leftcolor from "../../assets/images/home/clientlogos/Group 33.png";
import rightcolor from "../../assets/images/home/clientlogos/Group 34.png";
import { useSpring, animated } from "react-spring";
import { Clientlogose } from "../../Constants/Clientlogose";

 const logos = [
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo9,
  logo10,
  logo12,
  logo13,
  logo14,
  logo15,
  logo16,
  logo17,
  logo18,
  logo19,
  logo20,
  logo21,
  logo22,
  logo23,
  logo24,
  logo25,
  logo26,
  logo27,
  logo28,
  logo29,
  logo30,
  logo31,
  logo32,
  logo33,
  logo34,
];

const row1 = logos.slice(0, 15);
const row2 = logos.slice(10, 20);
const row3 = logos.slice(15, 33);
const row4 = logos.slice(20, 33);

export default function Ourclient() {
  const [showPlus, setShowPlus] = useState(false); // Control "+" visibility

  const { number } = useSpring({
    from: { number: 0 }, // Always starts at 0
    to: 300, // Animates to 300
    config: { duration: 1000 }, // 5-second animation
    onRest: () => setShowPlus(true), // Show "+" after animation completes
  });

  const { growth } = useSpring({
    from: { growth: 0 },
    to: 152,
    config: { duration: 1000 }, // 5-second animation
    onRest: () => setShowPlus(true), // Show "+" after animation completes
  });

  const { percet } = useSpring({
    from: { percet: 0 },
    to: 99.5,
    config: { duration: 1000 }, // 5-second animation
    onRest: () => setShowPlus(true), // Show "+" after animation completes
  });

  const settingsHome = {
    infinite: true,
    speed: 5000,
    slidesToShow: 8,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 0,
    pauseOnHover: false,
    rtl: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: true,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 530,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          cssEase: "linear",
        },
      },
    ],
  };

  const settingsHome2 = {
    infinite: true,
    speed: 6000,
    slidesToShow: 8,
    slidesToScroll: -1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 0,
    pauseOnHover: false,
    rtl: true,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          // slidesToScroll: 1,
          infinite: true,
          rtl: true, // Ensure RTL in responsive settings
          cssEase: "linear",
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 7,
          // slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          rtl: true, // Ensure RTL in responsive settings
          cssEase: "linear",
        },
      },
      {
        breakpoint: 530,
        settings: {
          slidesToShow: 3,
          // slidesToScroll: 1,
          infinite: true,
          rtl: true, // Ensure RTL in responsive settings
          cssEase: "linear",
        },
      },
    ],
  };

  return (
    <div className="Ourclient">
      <div className="bgImg">
        <img src={leftcolor} alt="left color" className="leftcolor" />
        <img src={rightcolor} alt="right color" className="rightcolor" />
      </div>

      <h1 className="heading">Our Client</h1>
      <p>
        We are trusted by the top-tier companies, We will talk about your
        problem and the <br /> best way to solve it even if you don’t choose us.
      </p>
      <div className="overlayNumber">
        <div className="numbers">
          <div className="number">
            <animated.h1>{number.to((n) => `${~~n} +`)}</animated.h1>
            <p>Successful Projects</p>
          </div>
          <div className="number">
            <animated.h1>{growth.to((n) => `${~~n} +`)}</animated.h1>
            <p>Customers and growing</p>
          </div>
          <div className="number">
            <animated.h1>{percet.to((n) => `${n.toFixed(1)} +`)}</animated.h1>
            <p>Success Rate</p>
          </div>
        </div>
      </div>

      <div>
        <div className="clientslogo">
          <Slider {...settingsHome}>
            {row1.map((item, id) => (
              <img key={id} src={item} alt="client logo" className="logo" />
            ))}
          </Slider>
          <Slider {...settingsHome2}>
            {row2.map((item, id) => (
              <img key={id} src={item} alt="client logo" className="logo" />
            ))}
          </Slider>
          <Slider {...settingsHome}>
            {row3.map((item, id) => (
              <img key={id} src={item} alt="client logo" className="logo" />
            ))}
          </Slider>
          <Slider {...settingsHome2}>
            {row4.map((item, id) => (
              <img key={id} src={item} alt="client logo" className="logo" />
            ))}
          </Slider>
          {/* <Slider {...settingsHome}>
            {row5.map((item, id) => (
              <img key={id} src={item} alt="client logo" className="logo" />
            ))}
          </Slider> */}
        </div>
      </div>
    </div>
  );
}


import React from "react";
import "./BlogPage.scss";
// import header
import Header from "../../compenents/Header/Header";

import BlogHome from "../../compenents/BlogHome/BlogHome";
import AllBlogs from "../../compenents/AllBlogs/AllBlogs";
import NewsAndEvent from "../../compenents/NewsAndEvent/NewsAndEvent";
import ContactForEvery from "../../compenents/ContactForEvery/ContactForEvery";


export default function BlogPage() {

  return (
    <div className="BlogPage">
      <div className="blog-header-nav">
        <Header className="hdr" />
      </div>
      <div className="blog-first-section">
        <BlogHome />
      </div>
      <div className="blog-second-section">
        <div className="blog-cards">
          <AllBlogs />
        </div>
      </div>
      <div className="blog-third-section">
        <NewsAndEvent />
      </div>
      <div className="JoinFormss">
        <ContactForEvery />
      </div>
    </div>
  );
}

import React from "react";
import "./BlogHome.scss";
import img1 from "../../assets/images/Blogs/bloghome/img1.png";
import img2 from "../../assets/images/Blogs/bloghome/img2.png";
import img3 from "../../assets/images/Blogs/bloghome/img3.png";
import { blogData } from "../../Constants/BlogData";
import { useNavigate } from "react-router-dom";
import SingleBlogDetails from "../SingleBlogDetails/SingleBlogDetails";

// const Blogdata = [
//   {
//     titleName: "What is supervised machine learning?",
//     img: img1,
//     autherName: "aryan patel",
//     date: "12-dec-2022",
//   },
//   {
//     img: img2,
//     titleName: "What is supervised machine learning?",
//     autherName: "aryan patel",
//     date: "12-dec-2022",
//   },
//   {
//     img: img3,
//     titleName: "What is supervised machine learning?",
//     autherName: "aryan patel",
//     date: "12-dec-2022",
//   },
// ];
export default function BlogHome() {
  const navigate = useNavigate();

  const handleProductClick = (blog) => {
    navigate(`/blog/${blog.id}`, { state: blog });
  };
  return (
    <div className="BlogHome">
      {/* <SingleBlogDetails data={blogData} /> */}
      <div className="service-btn">eHawkers Content</div>
      <div className="blog-heading">Resource center</div>
      <p className="blog-info">All the information you need</p>
      <div className="blog-section">
        <div className="blog-card">
          <div className="card-inner">
            {blogData.map((blog, id) => {
              return (
                <div  
                  className="blog-single-card"
                  key={id}
                  onClick={() => handleProductClick(blog)}
                >
                  <img src={blog.img} alt={blog.titleName} />
                  <div className="blog-text">
                    <h3 className="blog-title">{blog.titleName}</h3>
                    <div className="auther-container d-flex jusctify-content-between w-100">
                      <p className="auther">{blog.autherName}</p>
                      <p className="date ">{blog.date}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import "./MarketingService.scss";

import { strategyData1 } from "../../Constants/strategyAbout";
import { reletedWorkData } from "../../Constants/Reletedworks";
import BrandHome from "../../compenents/BrandComponent/BrandHome/BrandHome";
import { FaArrowDownLong } from "react-icons/fa6";
import Header from "../../compenents/Header/Header";
import ChartPart from "../../compenents/ChartPart/ChartPart";
import gif from "../../assets/images/Strat/greennote.gif";
import Firewall from "../../compenents/marketingServiceComts/Firewall/Firewall";
import Performance from "../../compenents/marketingServiceComts/Performance/Performance";
import PerfomanceWorkflow from "../../compenents/marketingServiceComts/perfomanceWorkflow/PerfomanceWorkflow";
import ReletedWork from "../../compenents/BrandComponent/ReletedWork/ReletedWork";
import AboutStrategy from "../../compenents/BrandComponent/AboutStrategy/AboutStrategy";
import JoinForm from "../../compenents/JoinForm/JoinForm";

const BMHomedata = {
  ButtonHeading: "Marketing Service",
  heading: `Some cool punchline about <span style="
    background: 
linear-gradient(90deg, #34A853 0%, #5DE18B 100%);
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
  ">Marketing</span>`,
  bg: "background: radial-gradient(164.38% 728.43% at 0% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%),linear-gradient(90deg, #34A853 0%, #5DE18B 100%);",

  description:
    "We specialize in creating effective marketing strategies to reach your target audience. Our team of experts will help you understand your audience, create engaging content, and optimize your marketing efforts.",
  gifSrc: gif,
  shadow: "0 4px 100px 50px rgba(88, 255, 146, 0.4)",
  paragraph:
    "We craft campaigns that resonate, engage, <br/> and convert, ensuring your message reaches <br/> the right audience at the right time.",
};

const mservice = [
  "Digital Marketing",
  "Performance Marketing",
  "E-Commerce Marketing",
  "Integreted Marketing",
];

export default function MarketingService() {
  return (
    <div className="MarketingService">
      <div className="marketing-header">
        <Header />
      </div>

      <div className="ms-home">
        <BrandHome {...BMHomedata} mservice={mservice} />
      </div>
      <div className="firewall-section">
        <Firewall />
      </div>
      <div className="Performance-section">
        <Performance />
      </div>
      <div className="perfomanceWorkflow-section">
        <PerfomanceWorkflow />
      </div>
      <div className="ms-about">
        <AboutStrategy
          title="What"
          span="Brand Marketing"
          strategyData={strategyData1}
        />
      </div>
      <div className="reletedwork-section">
        <ReletedWork
          heading="Related Works"
          paragraph="These are some of our most successful projects, demonstrating <br/> our ability to push creative boundaries."
          reletedWorkData={reletedWorkData}
        />
      </div>
      <div className="ms-section">
        <div className="afterHeader">
          <div className="firstPart">
            <div className="headingOfContact">
              <h3>
                If you like our concept <br /> Let’s talk {""}
                <span className="contact-heading-mid">business.</span>{" "}
              </h3>
            </div>
            <div className="ms-contactForm">
              <JoinForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import Visioncrafter from "../../compenents/visioncrafter/Visioncrafter";
import Joblistings from "../../compenents/JobListings/Joblistings";
import Reinvention from "../../compenents/Reinvention/Reinvention";
import CareerHeroSection from "../../compenents/CareerHeroSection/CareerHeroSection";
import Header from "../../compenents/Header/Header";
import "./Careers.scss";
import CompanyCrousel from "../../compenents/CompanyCrousel/CompanyCrousel";

function Careers() {
  return (
    <div className="Careermain">
      <div className="career-header">
        <Header />
      </div>

      <div className="careerHero">
        <CareerHeroSection />
      </div>
      <div className="companys">
      <CompanyCrousel/>
      </div>

      <div className="reinvention">
        <Reinvention />
      </div>
      <div className="joblistings" id="AllJob">
        <div className="leftTopGreenBg"></div>
        <div className="TopBlueBg"></div>
        <Joblistings />
      </div>
      <div className="visioncrafter">
        <Visioncrafter />
      </div>
    </div>
  );
}

export default Careers;

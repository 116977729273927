import React, { useState } from "react";
import "./OurPortPolio.scss";
import { clientData } from "../../Constants/ClientData.js";

import fenton from "../../assets/images/home/fenton.png";
import fent from "../../assets/images/home/fen.png";

import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function OurPortPolio() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("All");

  const headings = [
    "All",
    "Technology",
    "Media",
    "Brand",
    "Marketing",
    "Strategy",
  ];

  // Filtered data to display based on activeTab
  const filteredData =
    activeTab === "All"
      ? clientData // Show all images if no category is active
      : clientData.filter((item) => item.category === activeTab);

  const handleTabClick = (heading) => {
    setActiveTab((prevTab) => (prevTab === heading ? "All" : heading));
  };

  return (
    <div className="OurPortPolio">
      <h1 className="text">Our Portfolio</h1>
      <p>
        Making brands a damn site better. Brands win over fans when they’re
        brave enough <br /> to go beyond their creative comfort zone.
      </p>

      {/*  */}
      <div className="portHeader">
        <ul>
          {headings.map((heading) => (
            <li
              key={heading}
              onClick={() => handleTabClick(heading)}
              className={activeTab === heading ? "active" : ""}
            >
              {heading}
            </li>
          ))}
        </ul>
      </div>

      <div className="portfolio">
        {filteredData.slice(0, 5).map((item, index) => (
          <div
            key={index}
            className={`portfolio-item ${item.height}`}
            // onClick={(event) => {
            //   if (
            //     event.ctrlKey ||
            //     event.metaKey ||
            //     event.button === 1
            //   ) {
            //     // If Ctrl (or Command on macOS) or middle mouse button is clicked

            //     const newTabUrl = `${item.link}`;
            //     window.open(newTabUrl, "_blank");
            //   } else {
            //     // Default behavior for normal left-click
            //     window.scrollTo(0, 0);
            //     navigate(`${item.link}`);
            //   }
            // }}
            onClick={() => window.open(item.link, "_blank")}
          >
            <div className="image-overlay">
              <img
                src={item?.image}
                alt={item?.category}
                className="portfolio-image"
              />
            </div>
            {/*  */}
            <div className="client-details">
              <h3 className="client-title">{item.title}</h3>
              <p className="client-para">{item.paragraph}</p>
              <div className="buttons">
                {item.buttons.map((button, btnIndex) => (
                  // <a key={btnIndex} href={button.link} className="btn active">
                  //   {button.text}
                  // </a>
                  <p className="batan">{button.text}</p>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      {/*  */}

      <div className="button">
        <button
          className="batan"
          onClick={(event) => {
            if (event.ctrlKey || event.metaKey || event.button === 1) {
              const newTabUrl = "/Portfolio";
              window.open(newTabUrl, "_blank");
            } else {
              window.scrollTo(0, 0);
              navigate("/Portfolio");
            }
          }}
        >
          View all project <FaArrowRight className="arrow" />{" "}
        </button>
      </div>
    </div>
  );
}

import React from "react";
import "./Contact.scss";
import JoinForm from "../../compenents/JoinForm/JoinForm";
import Header from "../../compenents/Header/Header";
import {
  FaInstagram,
  FaLinkedin,
  FaYoutube,
  FaFacebook,
  FaBehance,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

function Contact() {
  return (
    <div className="mainContact">
      <div className="contectTopBg"></div>
      <div className="contactLeftBg"></div>
      <div className="contactLeftdown"></div>

      <div className="header">
        <Header />
      </div>
      <div className="afterHeader">
        <div className="firstPart">
          <div className="headingOfContact">
            <h3>Share Your Story </h3>
            <span className="contact-heading-mid">
              Product, Idea, Insight <br />
              or Dream
            </span>{" "}
          </div>
          <div className="contactForm">
            <JoinForm />
          </div>
        </div>

        <div className="secondPart">
          <div className="contactdetail">
            <div className="leftdiv">
              <div>
                <h3>Indore</h3>
                <p>
                  eHawkers Marketing <br /> LGF, Vishvadarshan <br /> Plot no.
                  50 AB Rd,
                  <br />
                  behind C21, Malviya Nagar Indore, <br /> Madhya Pradesh
                  (452010)
                </p>
              </div>
              <div className="phoneNumbers">
                <h4>(+91) 94068 11700</h4>
                <h4>(+91) 98266 17764</h4>
              </div>
            </div>

            <div className="rightdiv">
              <div className="emailSection">
                <div className="generalEmail">
                  <h3>General</h3>
                  <p>connect@ehawkers.co.in</p>
                </div>
                <div className="workEmail">
                  <h3>Work with us</h3>
                  <p>hr@ehawkers.co.in</p>
                </div>
              </div>

              <div className="socialSection">
                <h3>Follow us</h3>
                <div className="socialLinks">
                  <a
                    href="https://www.instagram.com/ehawkers_/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram className="icons" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/93088422/admin/dashboard/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin className="icons" />
                  </a>
                  <a
                    href="https://www.facebook.com/profile.php?id=61555330409891  "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebook className="icons" />
                  </a>
                  <a
                    href="https://www.youtube.com/@e-hawkersmarketing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaYoutube className="icons" />
                  </a>
                  <a
                    href="https://www.behance.net/ehawkers"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaBehance className="icons" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="map-main">
            <div className="mapgreenbg"></div>
            <div className="mapYellobg"></div>
            <div className="mapdetails">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3679.5706297681686!2d75.89473457446003!3d22.744195179369818!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa756830634d6d6cb%3A0xb29b58e9b8d876cd!2se-Hawkers%20Marketing!5e0!3m2!1sen!2sin!4v1734421143848!5m2!1sen!2sin"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;

import React, { useState } from "react";
import "./AditForm.scss";
import ellipse1 from "../../assets/images/home/audit/Subtract.png";
import ellipse2 from "../../assets/images/home/audit/Subtract (1).png";
import blur1 from "../../assets/images/home/audit/blur2.png";
import blur2 from "../../assets/images/home/audit/blur1 (1).png";
import blur3 from "../../assets/images/home/audit/blur1.png";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function AditForm() {
  // State to hold form data
  const [formData, setFormData] = useState({
    fullname: "",
    business: "",
    websiteURL: "",
    phoneNumber: "",
    email: "",
    comments: "",
  });

  const validate = () => {
    // Name validation (minimum 3 characters)
    if (formData.fullname.trim().length < 3) {
      return "Name should be at least 3 characters long.";
    }

    // Business Name validation (minimum 3 characters)
    if (formData.business.trim().length < 3) {
      return "Business name should be at least 3 characters long.";
    }

    // Website URL validation (optional, but you can add URL regex if needed)
    if (formData.websiteURL.trim().length < 3) {
      return "Website URL should be at least 3 characters long.";
    }

    // Phone Number validation (must be digits and 10 characters long)
    if (!/^\d{10}$/.test(formData.phoneNumber.trim())) {
      return "Phone number should be 10 digits.";
    }

    // Email validation (must include @ symbol)
    if (!/\S+@\S+\.\S+/.test(formData.email.trim())) {
      return "Email should be valid";
    }

    // Message validation (minimum 3 characters)
    if (formData.comments.trim().length < 3) {
      return "Message should be at least 3 characters long.";
    }

    // Return true if no errors, otherwise false
    return "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validate();
    if (errors.length != 0) {
      toast.warn(errors, {
        position: "top-right",
        autoClose: 4000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
      return;
    }
    const config = {
      url: process.env.REACT_APP_BASE_URL + `api/AddNewAudit`,
      method: "post",
      data: {
        name: formData?.fullname,
        businessName: formData?.business,
        websiteUrl: formData?.websiteURL,
        phoneNumber: formData?.phoneNumber,
        email: formData?.email,
        message: formData?.comments,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response?.data?.status == true) {
          toast.success("Contact Details Get Successfully", {
            position: "top-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });

          setFormData({
            fullname: "",
            business: "",
            websiteURL: "",
            phoneNumber: "",
            email: "",
            comments: "",
          });
        }
        if (response?.data?.status == false) {
          if (response?.data?.response_code == 500) {
            toast.error("Problem With Data Fetching", {
              position: "top-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          } else if (response?.data?.response_code == 404) {
            toast.error("Problem With Data Fetching", {
              position: "top-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.response_code == 401) {
          toast.error("Your token is expired. Please login again.", {
            position: "top-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        } else {
          toast.warn("Problem With Data Fetching", {
            position: "top-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        }
      });
  };

  // Handle input changes dynamically
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(e.target);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="contact-main">
      <div className="contactform-outer">
        <div className="form-above">
          <img src={ellipse1} alt="" className="bg1" />
          <img src={blur1} alt="" className="bg1" />
          <h1>Get Your Brand Audit</h1>
          <p className="form-outer-content">
            By requesting your free brand audit, you're taking the first step
            towards unlocking
            <br /> your brand's full potential. Our diverse team will provide
            insights & recommendations <br />
            tailored to your needs
          </p>
        </div>
        <form className="form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="fullname"
            value={formData.fullname}
            onChange={handleChange}
            placeholder="Full Name"
          />
          <input
            type="text"
            name="business"
            value={formData.business}
            onChange={handleChange}
            placeholder="Business Name"
          />
          <input
            type="text"
            name="websiteURL"
            value={formData.websiteURL}
            onChange={handleChange}
            placeholder="Website URL"
          />
          <div className="number-section">
            <input
              type="text" // Use text input for flexibility
              name="phoneNumber"
              value={
                formData.phoneNumber ? `+91 ${formData.phoneNumber}` : "" // Show only when there's a value
              }
              onChange={(e) => {
                // Ensure only numbers are entered
                const inputValue = e.target.value.startsWith("+91 ")
                  ? e.target.value.replace("+91 ", "")
                  : e.target.value;

                if (/^\d*$/.test(inputValue)) {
                  setFormData({ ...formData, phoneNumber: inputValue }); // Update only the numeric part
                }
              }}
              placeholder="+91 - XXXXX XXXXX" // Placeholder for the input
              onFocus={(e) => {
                if (!formData.phoneNumber) {
                  // Add +91 when the user focuses and there's no value
                  e.target.value = "+91 ";
                }
              }}
              onBlur={(e) => {
                if (e.target.value === "+91 ") {
                  // Remove +91 if no number is entered
                  e.target.value = "";
                }
              }}
            />

            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="example@gmail.com"
            />
          </div>
          <div className="textaria">
            <textarea
              name="comments"
              value={formData.comments}
              onChange={handleChange}
              placeholder="Share your goals"
              rows={4}
            ></textarea>
            <button type="submit">Get Audit</button>
          </div>
        </form>
        <img src={blur3} alt="" className="bg2" />
        <img src={ellipse2} alt="" className="bg2" />
        <img src={blur2} alt="" className="bg2 blur" />
      </div>

      <ToastContainer />
    </div>
  );
}

export default AditForm;

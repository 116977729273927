import React from "react";
import "./BrandHome.scss";
import lefticon from "../../../assets/images/brandPage/arrow.png";

export default function BrandHome({
  heading,
  gifSrc,
  paragraph,
  ButtonHeading,
  mservice,
  bg,
  shadow,
  showIcon,
  customStyle,
}) {
  return (
    <div className="BrandHome-container">
      <div className="BrandHome">
        <div className="brandLeft">
          <button className="brandButton">{ButtonHeading}</button>
          <h1
            className="buildingHead"
            style={{ background: bg }}
            dangerouslySetInnerHTML={{ __html: heading }}
          ></h1>
        </div>
        <div className="brandRight">
          <div className="brand-img-Section">
            <div className="brandGif" style={{ boxShadow: shadow }}>
              <img src={gifSrc} alt="Brand Gif" className="brandGif" />
            </div>
          </div>
          <p
            className="Brand-paragraph"
            dangerouslySetInnerHTML={{ __html: paragraph }}
          ></p>
        </div>
      </div>
      <div className="discover-all-services">
        <p className="dis-paragraph">Discover more</p>
        <div className="marketing-services">
          <div className="service-card-con">
            {mservice.map((service) => (
              <div key={service} className="marketing-service-item">
                <p className="name-of-service">{service}</p>
              </div>
            ))}
          </div>
          {/* {showIcon && (
            <img
              src={lefticon}
              alt="left arrow"
              className={`lefticon ${customStyle ? "custom-icon" : ""}`}
            />
          )} */}
           <img
              src={lefticon}
              alt="left arrow"
              className={`lefticon ${customStyle ? "custom-icon" : ""}`}
            />
        </div>
      </div>
    </div>
  );
}

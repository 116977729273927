import React, { useState, useContext } from "react";
import "./DialogForm.scss";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

// context api

const DialogForm = ({ isOpen, handleDialog }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  if (!isOpen) {
    return null;
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateYou = () => {
    // let newErrors = {};

    // Name validation (minimum 3 characters)
    if (formData.name.trim().length < 3) {
      return "Name should be at least 3 characters long.";
    }

    // Phone Number validation (must be digits and 10 characters long)
    if (!/^\d{10}$/.test(formData.phoneNumber.trim())) {
      return "Phone number should be 10 digits.";
    }

    // Email validation (must include @ symbol)
    if (!/\S+@\S+\.\S+/.test(formData.email.trim())) {
      return "Email should be valid";
    }

    // Message validation (minimum 3 characters)
    if (formData.message.trim().length < 3) {
      return "Message should be at least 3 characters long.";
    }

    // Return true if no errors, otherwise false
    return "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateYou();
    if (errors.length != 0) {
      toast.warn(errors, {
        position: "bottom-right",
        autoClose: 4000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
      return;
    }
    const config = {
      url: process.env.REACT_APP_BASE_URL + `api/createContact`,
      method: "post",
      data: formData,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Hello");
        console.log(response?.data);
        if (response?.data?.status == true) {
          console.log("dfghj", response?.data);
          toast.success("Contact Details Get Successfully", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
        }
        if (response?.data?.status == false) {
          if (response?.data?.response_code == 500) {
            toast.error("Problem With Data Fetching", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          } else if (response?.data?.response_code == 404) {
            toast.error("Problem With Data Fetching", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.response_code == 401) {
          toast.error("Your token is expired. Please login again.", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        } else {
          toast.warn("Problem With Data Fetching", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        }
      });
  };
  return (
    <div className="dialog-form-container" onClick={handleDialog}>
      <div
        className="dialog-form"
        onClick={(e) => e.stopPropagation()}
        style={{
          background: `linear-gradient(
      128.7deg,
rgb(0, 0, 0) -119.68%,
rgb(27, 24, 24) 22.65%,
rgb(0, 0, 0) 102.79%
    )`,
        }}
      >
        <h5 className="text-center">Let's talk about</h5>
        <i class="bi bi-x-circle" onClick={handleDialog}></i>

        <h1
          className="text-center"
          style={{
            color: "#45a0e5",
            fontSize: "3rem",
            fontWeight: "900",
          }}
        >
          YOU
        </h1>

        <p className="fw-bold form-para mt-1 text-center w-100">
          We will talk about your problem and the best way to solve it even if
          you don’t choose us.
        </p>

        <form className="row  justify-content-center">
          <div className="col-12">
            <input
              type="text"
              className="form-control rounded-3"
              id="input1"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>

          <div className="col-12">
            <input
              type="email"
              className="form-control rounded-3"
              id="input2"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>

          <div className="col-12">
            <input
              type="tel"
              className="form-control rounded-3"
              id="input3"
              name="phoneNumber"
              placeholder="Phone Number"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </div>

          <div className="col-12">
            <textarea
              type="text"
              className="form-control rounded-3"
              id="input4"
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
            />
          </div>

          <div className="togg">
            <div className="form-check form-switch togg-d">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                name="isSubscribed"
                checked={formData.isSubscribed}
                onChange={handleChange}
              />
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckDefault"
              >
                Subscribe to our updates
              </label>
            </div>
          </div>

          <div className="col-auto">
            <button
              type="submit"
              className="letsTalk-btn btn btn-primary p-1 px-4 border-0 rounded-5"
              onClick={handleSubmit}
            >
              Let's Talk
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DialogForm;

import React from "react";
import "./SingleBlogDetails.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";

const SingleBlogDetails = ({ data }) => {
  const { state: blog } = useLocation();
  const navigate = useNavigate();

  if (!blog) {
    return <h2>No product found!</h2>;
  }

  return (
    <>
      <button className="btn-button" onClick={() => navigate(-1)}>
        <IoArrowBackOutline />
      </button>

      <h1 className="text-center hd">Blog Heading</h1>

      <div className="overlay-singleblog">
        <div
          className={`single-blog ${
            blog.layout === "image-left" ? "image-left" : "image-right"
          }`}
        >
          <div className="image-container">
            <img className="fristimg" src={blog.image} alt="Blog" />
            <p className="blog-para">{blog.details}</p>
            <img src={blog.img} alt="" className="Secondimg" />
            <p className="blog-para">{blog.Description}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleBlogDetails;

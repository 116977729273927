import React from "react";
import "./MediaService.scss";
import "../MarketingService/MarketingService.scss";
import BrandHome from "../../compenents/BrandComponent/BrandHome/BrandHome";
import ReletedWork from "../../compenents/BrandComponent/ReletedWork/ReletedWork";
import { reletedWorkData } from "../../Constants/Reletedworks";
import JoinForm from "../../compenents/JoinForm/JoinForm";
import gif from "../../assets/images/Strat/greennote.gif";
import MediaAbout from "../../compenents/MediaAbout/MediaAbout";
import MediaVideo from "../../compenents/MediaVideo/MediaVideo";
import mdvideo from "../../assets/images/mediaservice/mdvideo.gif";
import Header from "../../compenents/Header/Header";

const BMHomedata = {
  ButtonHeading: "Media Solutions",
  heading: `Some cool punchline <br/> about <span style="
   background: 
linear-gradient(90.98deg, #EA4335 14.23%, #4285F4 110.72%);
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
  "> Media Solutions </span>`,

  description:
    "Write a appealing paragraph about media solutions and what you are providing in two to three lines.",
  gifSrc: mdvideo,
  bg: "",
  shadow: "0 4px 100px 50px rgba(125, 66, 244, 0.38)",
  paragraph:
    "Write a appealing paragraph about media <br/> solutions and what you are providing in two <br/> to three lines.",
};
const mservice = [
  "Content Creation",
  "Public Relations (PR)",
  "Event Coverage & Media Support",
  "Social Media-Specific Content ",
];
export default function MediaService() {
  return (
    <div className="MediaService">
      <div className="mss-header">
        <Header />
      </div>
      <div className="media-home">
        <BrandHome {...BMHomedata} mservice={mservice} customStyle={false} />
      </div>
      <div className="media-video-section">
        <MediaVideo />
      </div>
      <div className="media-about-details">
        <MediaAbout />
      </div>
      <div className="reletedwork-section">
        <ReletedWork
          heading=" Related Works"
          paragraph="These are some of our most successful projects,  demonstrating <br/> our ability to push creative boundaries."
          reletedWorkData={reletedWorkData}
        />
      </div>
      <div className="ms-section">
        <div className="afterHeader">
          <div className="firstPart">
            <div className="headingOfContact">
              <h3>
                If you like our concept <br /> Let’s talk {""}
                <span className="contact-heading-mid">business.</span>{" "}
              </h3>
            </div>
            <div className="ms-contactForm">
              <JoinForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

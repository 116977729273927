import video01 from "../assets/images/home/service/01.mp4";
import video02 from "../assets/images/home/service/02.mp4";
import video03 from "../assets/images/home/service/03.mp4";
import video04 from "../assets/images/home/service/04.mp4";
import video05 from "../assets/images/home/service/05.mp4";

export const serviceData = [
  {
    link: "/marketing-service",
    uperbg: "#EFC038",
    centerbg: "#F7C942",
    bottombg: "#FFDD7A",
    image: video05,
    heading: "Marketing Solutions",
    department: ["Strategy", "Analysis", "Planning"],
    description:
      "Producing dynamic content and strategic campaigns designed to capture attention, evoke emotions, and enhance brand reach.",
  },
  {
    link: "/MediaService",
    uperbg: "#4285F4",
    centerbg: "#7CADFF",
    bottombg: "#7CADFF",
    image: video02,
    heading: "Media Solutions",
    department: ["Web", "Mobile", "E-commerce"],
    description:
      "Transforming concepts into impactful visuals and campaigns that drive engagement and inspire action across platforms.",
  },
  {
    link: "/techservice",
    uperbg: "#EA8335",
    centerbg: "#EA4335",
    bottombg: "#F7C942",
    image: video04,
    heading: "Tech Solutions",
    department: ["Innovation", "Efficiency", "Planning"],
    description:
      "Creating innovative multimedia experiences that align with your brand’s message and foster deeper audience connections.",
  },
  {
    link: "/brand",

    uperbg: "#5AEDAB",
    centerbg: "#5AEDAE",
    bottombg: "#5CEFB2",
    image: video03,
    heading: "Brand Solutions",
    department: ["Identity", "Messaging", "Campaigns"],
    description:
      "Your brand is the foundation of your business. It creates a connection, builds trust, and establishes a lasting impression. Our services empower you to define your voice, refine your image, and captivate your audience with purpose.",
  },

  {
    link: "/strategy-service",

    uperbg: "#EA4335",
    centerbg: "#FF7C71",
    bottombg: "#FF8D84",
    image: video01,
    heading: "Strategy Solutions",
    department: ["Growth", "Planning", "Execution"],
    description:
      "Blending creativity with strategy to deliver high-quality content and campaigns that drive visibility and success.",
  },
];

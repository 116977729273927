import fenton from "../assets/images/home/fenton.png";
import fent from "../assets/images/home/fen.png";
import image from "../assets/images/home/clients/image.png";

export const clientData = [
  {
    link: "https://fantom-e.vercel.app",
    image: fenton,
    title: "Fenton",
    paragraph:
      "Designed and developed a modern, user-friendly website for Fenton using the MERN (MongoDB, Express.js, React.js, Node.js) technology stack. Focused on creating an engaging UI/UX design to ensure seamless navigation and an enhanced user experience. The project emphasized responsive design, ensuring compatibility across devices, and included features tailored to meet Fenton business needs.",
    category: "Technology",

    buttons: [
      { text: "UI/UX Design", link: "/project2" },
      { text: "Web Development", link: "/contact2" },
      { text: "Graphic Design", link: "/learn-more2" },
    ],
  },
  {
    link: "#",

    image: image,
    title: "Client 1",
    paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam pharetra fermentum ante vitae convallis. Mauris finibus at lectus et commodo. Aenean lacinia neque a porttitor.",

    category: "Media",
    height: "smallImg",
    buttons: [
      { text: "UI/UX Design", link: "/project2" },
      { text: "Web Development", link: "/contact2" },
      { text: "Graphic Design", link: "/learn-more2" },
    ],
  },
  {
    link: "#",

    image: fent,
    title: "Tech two",
    paragraph:
      "Client 3's project was a great collaboration, and we're happy with the impact it made.",
    category: "Brand",
    height: "smallImg",
    buttons: [
      { text: "UI/UX Design", link: "/project2" },
      { text: "Web Development", link: "/contact2" },
      { text: "Graphic Design", link: "/learn-more2" },
    ],
  },
  {
    link: "#",

    image: fent,
    title: "Client 4",
    paragraph:
      "Client 4 trusted us with a complex project, and the results speak for themselves.",
    category: "Marketing",
    height: "smallImg",
    buttons: [
      { text: "UI/UX Design", link: "/project2" },
      { text: "Web Development", link: "/contact2" },
      { text: "Graphic Design", link: "/learn-more2" },
    ],
  },
  {
    link: "#",

    image: fent,
    title: "Client 5",
    paragraph:
      "Client 5 came to us for a creative solution, and we delivered beyond expectations.",
    category: "Strategy",
    height: "smallImg",
    buttons: [
      { text: "UI/UX Design", link: "/project2" },
      { text: "Web Development", link: "/contact2" },
      { text: "Graphic Design", link: "/learn-more2" },
    ],
  },
];

import img1 from "../assets/images/brandPage/relimg1.png";
import img2 from "../assets/images/brandPage/relimg2.png";
import img3 from "../assets/images/brandPage/relimg3.png";
import img4 from "../assets/images/brandPage/relimg4.png";


export const reletedWorkData = [
  {
    img: img1,
    heading: "TWICKS.COM",
    details:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam <br/> pharetra fermentum ante vitae convallis. Mauris finibus at <br/> lectus et commodo. Aenean lacinia neque a porttitor.",
    degignation: ["UI/UX designer", "web devlopment", "Graphics desing"],
  },
  {
    img: img2,
    heading: "TWICKS.COM",
    details:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam <br/> pharetra fermentum ante vitae convallis. Mauris finibus at <br/> lectus et commodo. Aenean lacinia neque a porttitor.",
    degignation: ["UI/UX designer", "web devlopment", "Graphics desing"],
  },
  {
    img: img3,
    heading: "TWICKS.COM",
    details:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam <br/> pharetra fermentum ante vitae convallis. Mauris finibus at <br/> lectus et commodo. Aenean lacinia neque a porttitor.",
    degignation: ["UI/UX designer", "web devlopment", "Graphics desing"],
  },
  {
    img: img4,
    heading: "TWICKS.COM",
    details:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam <br/> pharetra fermentum ante vitae convallis. Mauris finibus at <br/> lectus et commodo. Aenean lacinia neque a porttitor.",
    degignation: ["UI/UX designer", "web devlopment", "Graphics desing"],
  },
];

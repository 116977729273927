import React, { useState, useEffect, useRef } from "react";
import "./ServicePage.scss";
import OurServices from "../../compenents/OurServices/OurServices";
import ChallengeAccept from "../../compenents/ChallengeAccept/ChallengeAccept";
import Header from "../../compenents/Header/Header";
import OurPortPolio from "../../compenents/OurPortPolio/OurPortPolio";
import FullStack from "../../compenents/FullStack/FullStack";
import ContactForEvery from "../../compenents/ContactForEvery/ContactForEvery";
import { useSpring, animated } from "react-spring";
import DialogForm from "../../compenents/DialogForm/DialogForm";
import { serviceData } from "../../Constants/ServiceDetails";
//
import { gsap } from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { ScrollSmoother } from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function ServicePage() {
  const [openDialog, setOpenDialog] = useState(false);

  const handleDialog = (e) => {
    e.stopPropagation();
    setOpenDialog(!openDialog);
  };
  const { number } = useSpring({
    from: { number: 0 },
    to: { number: 365 },
    config: { duration: 2000 },
  });

  const { houre } = useSpring({
    from: { houre: 0 }, // Always starts at 0
    to: { houre: 24 }, // Animates to 300
    config: { duration: 2000 }, // 5-second animation
  });

  //
  gsap.to(".kk h1", {
    x: "-100%",
    scrollTrigger: {
      trigger: ".kk",
      start: "top center",
      end: "top 80%",
      scrub: true,
    },
  });

  return (
    <div className="ServicePage">
      <div className="homeOuter">
        <div className="header">
          <Header />
        </div>
        <div className="homeSection">
          <div className="leftSection">
            <h1>
              We create the <br />
              <span>Global Showroom</span>
            </h1>
            <p>
              Your Creative, Media & Technology Transformation Partner We take{" "}
              <br />
              care of everything Digital, we are the experts in boosting your
              brand, <br /> elevating your online visibility, and propelling
              your business to success.
            </p>
            <button onClick={handleDialog}>Connect Now</button>
          </div>
          <div className="rightSection">
            <div className="days">
              <div className="day">
                <animated.h1>{number.to((n) => ~~n)}</animated.h1>
                <h5>days</h5>
              </div>
              <div className="hour">
                <div className="topcolor"></div>
                <animated.h1>{houre.to((n) => ~~n)}</animated.h1>
                <h5>hour</h5>
              </div>
              <p>
                We Work <br /> every single day
              </p>
            </div>
            <div className="rgbBg"></div>
          </div>
        </div>
        <div className="dialog-container">
          <DialogForm isOpen={openDialog} handleDialog={handleDialog} />
        </div>
      </div>

      <div className="kk" style={{ height: "110vh", width: "100vw" }}>
        <h1 style={{ fontSize: "18vw", whiteSpace: "nowrap" }}>
          ehawkers marketing ji ........
        </h1>
      </div>

      <div className="sectiontwo">
        <OurServices />
      </div>

      <div className="innerdiv">
        <div className="bluebg"></div>
        <FullStack />
      </div>
      <div className="rightBlueBg"></div>
      <div className="challengeSection">
        <ChallengeAccept />
      </div>

      <div className="PortFolio">
        <OurPortPolio />
      </div>

      <div className="JoinForms">
        <ContactForEvery />
      </div>
    </div>
  );
}

import React from "react";
import BrandHome from "../../compenents/BrandComponent/BrandHome/BrandHome";
import Header from "../../compenents/Header/Header";
import ChartPart from "../../compenents/ChartPart/ChartPart";
import "./StrategyService.scss";
import checkgif from "../../assets/images/Strat/01.gif";
import ReletedWork from "../../compenents/BrandComponent/ReletedWork/ReletedWork";
import { reletedWorkData } from "../../Constants/Reletedworks";
import JoinForm from "../../compenents/JoinForm/JoinForm";

const BMHomedata = {
  ButtonHeading: "Strategy Solutions",
  heading: "Empowering Your Vision with Strategic <span> Precision</span>  ",
  description:
    "Tailored solutions to navigate complexities, accelerate growth, and future-proof your business.",
  gifSrc: checkgif,
  shadow: "0 4px 100px 50px rgba(66, 134, 244, 0.34)",

  paragraph:
    "Tailored solutions to navigate complexities, <br/> accelerate growth, and future-proof your <br/> business.",
};

const mservice = [
  "Market Analysis & Insights",
  "Business Growth Strategies",
  "Digital Transformation Strategy ",
  "Sales Optimization",
  "Crisis Management Strategy ",
];

function StrategyService() {
  return (
    <div className="Strategry-Service_Body">
      <div className="Strategry-header">
        <Header />
      </div>

      <div className="Strategry-home">
        <BrandHome {...BMHomedata} mservice={mservice} />
      </div>

      <div className="marketingCharPart">
        <ChartPart />
      </div>

      <div className="reletedwork-section">
        <ReletedWork
          heading="Our First Related Works"
          paragraph="These are some of our most successful projects, demonstrating our ability to push creative boundaries."
          reletedWorkData={reletedWorkData}
        />
      </div>
      <div className="ms-section">
        <div className="afterHeader">
          <div className="firstPart">
            <div className="headingOfContact">
              <h3>
                If you like our concept <br /> Let’s talk {""}
                <span className="contact-heading-mid">business.</span>{" "}
              </h3>
            </div>
            <div className="ms-contactForm">
              <JoinForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StrategyService;

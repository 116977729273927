import React from "react";
import "./CompanyCrousel.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img from "../../assets/images/home/clientlogos/logo ehawkers/01lic.webp";
import img1 from "../../assets/images/collage/a.jpg";
import img2 from "../../assets/images/collage/bb.jpg";
import img3 from "../../assets/images/collage/cc.jpg";
import img4 from "../../assets/images/collage/dd.jpg";
import img5 from "../../assets/images/collage/ee.jpg";
import img6 from "../../assets/images/collage/ff.jpg";
import img7 from "../../assets/images/collage/gg.jpg";
import img8 from "../../assets/images/collage/hh.png";
import img9 from "../../assets/images/collage/ii.png";
import img10 from "../../assets/images/collage/jj.jpeg";

const collage = [

  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
];

export default function CompanyCrousel() {
  const settings = {
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    pauseOnHover: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  return (
    <div>
      <div className="our-clients">
        <div className="our-clients-sub row">
          <div className="heading col-md-3">
            <h2>Our collaboration institution.</h2>
            {/* <h6>Teams Worldwide Love Us</h6> */}
          </div>
          <div className="logo-carousel col-md-9">
            <div id="gallery" className="scrollc" style={{ margin: "1rem" }}>
              <Slider {...settings} className="owl-theme">
                {collage.map((logo, id) => (
                  <div className="h-100" key={id}>
                    <div className="item ">
                      <img src={logo} alt="collage" />
                    </div>
                  </div>
                ))}
              
                {/* <div className="h-100">
                  <div className="item p-2">
                    <img src={img} alt=" Cipla Client" />
                  </div>
                </div>
                <div className="h-100">
                  <div className="item p-2">
                    <img src={img} alt=" intas client" />
                  </div>
                </div>
                <div className="h-100">
                  <div className="item p-2">
                    <img src={img} alt="lupin client" />
                  </div>
                </div>
                <div className="h-100">
                  <div className="item p-2">
                    <img src={img} alt="mosiac client" />
                  </div>
                </div>
                <div className="h-100">
                  <div className="item p-2">
                    <img src={img} alt="shivganga client" />
                  </div>
                </div>
                <div className="h-100">
                  <div className="item p-2">
                    <img src={img} alt="sunpharma client" />
                  </div>
                </div>
                <div className="h-100">
                  <div className="item p-2">
                    <img src={img} alt="v-one client" />
                  </div>
                </div> */}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import "./Reinvention.scss";

function Reinvention() {
  // Content Array for Dynamic Cards
  const cardContent = [
    {
      number: "01.",
      title: "What we offer",
      description:
        "What we offer An opportunity to reinvent your world. Work with diverse and driven people on global projects that are truly shaping the world. Seize the opportunity to learn, grow, and realize your ambitions.",
    },
    {
      number: "02.",
      title: "How we do it",
      description:
        "By acting boldly. Accelerate your growth through curated learning and development opportunities. Get the autonomy to act with confidence and create the future. Are you ready to never stand still?",
    },
    {
      number: "03.",
      title: "Why we do it",
      description:
        "Because we care deeply. Lead the next chapter by creating real-world impact. Share our values, be driven by our strong sense of purpose, and act with compassion to elevate yourself and the world around you.",
    },
  ];

  return (
    <div className="reinvention-main">
      <div className="reinvention-inner-main">
        {/* Heading Section */}
        <div className="reinvention-heading-content">
          <p className="reinvention-heading">
            Our <span>reinvention</span> starts with you
          </p>
          <p className="reinvention-heading-para">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
            gravida pulvinar velit, id porttitor <br /> augue viverra ac. Etiam
            consequat fringilla lacus non fringilla.
          </p>
        </div>

        {/* Dynamic Reinvention Cards */}
        <div className="reinvention-content">
          {cardContent.map((card, index) => (
            <div className="reinvention-card" key={index}>
              <p className="reinvention-number">{card.number}</p>
              <hr />
              <p className="reinvention-content-head">{card.title}</p>
              <p className="reinvention-para">{card.description}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="reinvention-background-effect">
        
      </div>
      
    </div>
  );
}

export default Reinvention;

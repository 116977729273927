import React, { useEffect, useState } from "react";
import "./MediaVideo.scss";
import machine from "../../assets/images/mediaservice/m1.png";
import glow from "../../assets/images/mediaservice/glow.png";
import nglow from "../../assets/images/mediaservice/nglow.png";
import mglow from "../../assets/images/mediaservice/mglow.png";
import video from "../../assets/images/mediaservice/mediavideo.mp4";

export default function MediaVideo() {
  const [isGlowing, setIsGlowing] = useState(false);

  useEffect(() => {
    // Function to handle the glow cycle
    const startGlowCycle = () => {
      setIsGlowing(true); // Start glowing
      setTimeout(() => {
        setIsGlowing(false); // Turn off glow after 500ms
      }, 60000); // Duration of glow
    };

    // Start the glow immediately when the page loads
    startGlowCycle();

    // Set an interval to repeat the glow cycle every 2 minutes
    const interval = setInterval(() => {
      startGlowCycle();
    }, 61000); // 2 minutes = 120,000 ms

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="MediaVideo">
      <div className="video-sec-media">
        <div className="video">
          <video autoPlay loop muted className="video">
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="machine">
            <div className="machine-inner">
              <img src={machine} alt="machine" className="mm" />
              <img
                src={glow}
                className={`glow ${isGlowing ? "visible" : "hidden"}`}
              />
              <img
                src={nglow}
                className={`nglow ${isGlowing ? "visible" : "hidden"}`}
              />
              <img
                src={mglow}
                className={`mglow ${isGlowing ? "visible" : "hidden"}`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

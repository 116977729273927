import React from "react";
import "./NewsAndEvent.scss";
import { blogData } from "../../Constants/BlogData";

import img1 from "../../assets/images/Blogs/bloghome/img1.png";
import img2 from "../../assets/images/Blogs/bloghome/img2.png";
import img3 from "../../assets/images/Blogs/bloghome/img3.png";
import { useNavigate } from "react-router-dom";
const NewsEventgdata = [
  {
    img: img1,
    titleName: "What is supervised machine learning?",
    autherName: "aryan patel",
    date: "12-dec-2022",
  },
  {
    img: img2,
    titleName: "What is supervised machine learning?",
    autherName: "aryan patel",
    date: "12-dec-2022",
  },
  {
    img: img3,
    titleName: "What is supervised machine learning?",
    autherName: "aryan patel",
    date: "12-dec-2022",
  },
];
export default function NewsAndEvent() {
  const navigate = useNavigate();

  const handleProductClick = (blog) => {
    navigate(`/blog/${blog.id}`, { state: blog });
  };
  return (
    <div className="NewsAndEvent">
      <div className="news-event">
        <h1 className="news-heading">News & Events</h1>
        <div className="news-line"></div>
      </div>
      <div className="news-event-content">
        <div className="newsEvent-card">
          <div className="newsEvent-inner">
            {blogData.map((blog, id) => {
              return (
                <div className="newsEvent-single-card" key={id}
                onClick={() => handleProductClick(blog)}
>
                  <img src={blog.img} alt={blog.titleName} />
                  <div className="newsEvent-text">
                    <h3 className="newsEvent-title">{blog.titleName}</h3>
                    <div className="newsEvent-container d-flex jusctify-content-between w-100">
                      <p className="newsEvent-heading">{blog.autherName}</p>
                      <p className="date ">{blog.date}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

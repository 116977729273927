import React, { useState } from "react";
import "./Portfolio.scss";
import { clientData } from "../../Constants/ClientData.js";
import Header from "../../compenents/Header/Header.jsx";
import { RiSearchLine } from "react-icons/ri";

export default function Portfolio() {
  const [activeTab, setActiveTab] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredDataCheck, setFilteredDataCheck] = useState(clientData);

  const headings = [
    "All",
    "Technology",   
    "Media",
    "Brand",
    "Marketing",
    "Strategy",
  ];

  const filteredData = clientData.filter(
    (item) => activeTab === "All" || item.category === activeTab
  );

  const handleTabClick = (heading) => {
    setActiveTab((prevTab) => (prevTab === heading ? "All" : heading));
  };

  const handleSearchChangeForFilter = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filtered data based on search query
  const filteredResults = searchQuery
    ? filteredDataCheck.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  return (
    <div className="OurPortPolio">
      <div className="divheader mt-3 ">
        <Header />
      </div>
      <div className="flylayer">
        <div className="flyinner">
          <h1 className="text">Our Portfolio</h1>
          <p>
            Making brands a damn site better. Brands win over fans when they’re
            brave enough <br /> to go beyond their creative comfort zone.
          </p>
        </div>

        <div className="serchbar">
          <input
            type="text"
            placeholder="Search Projects here..."
            value={searchQuery}
            onChange={handleSearchChangeForFilter}
          />{" "}
          <RiSearchLine className="serchicon" />{" "}
        </div>
        <div className="CheckSerachbar">
          {filteredResults?.map((item, index) => (
            <div key={index} className={`portfolio-item ${item.height}`}>
              <div className="image-overlay">
                <img
                  src={item?.image}
                  alt={item?.category}
                  className="portfolio-image"
                />
              </div>
              <div className="client-details">
                <h3 className="client-title">{item.title}</h3>
                <p className="client-para">{item.paragraph}</p>
                <div className="buttons">
                  {item.buttons.map((button, btnIndex) => (
                    <p key={btnIndex} className="batan">
                      {button.text}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="portHeader">
        <ul>
          {headings.map((heading) => (
            <li
              key={heading}
              onClick={() => handleTabClick(heading)}
              className={activeTab === heading ? "active" : ""}
            >
              {heading}
            </li>
          ))}
        </ul>
      </div>

      <div className="portfolio">
        {filteredData.length > 0 ? (
          filteredData.slice(0, 5).map((item, index) => (
            <div key={index} className={`portfolio-item ${item.height}`}
            onClick={() => window.open(item.link, "_blank")}
            >
              <div className="image-overlay">
                <img
                  src={item?.image}
                  alt={item?.category}
                  className="portfolio-image"
                />
              </div>
              <div className="client-details">
                <h3 className="client-title">{item.title}</h3>
                <p className="client-para">{item.paragraph}</p>
                <div className="buttons">
                  {item.buttons.map((button, btnIndex) => (
                    <p key={btnIndex} className="batan">
                      {button.text}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No items found for your search.</p>
        )}
      </div>
    </div>
  );
}

// import React from "react";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";
// import { Line } from "react-chartjs-2";

// // Register components
// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// const LineChart = () => {
//   const chartData = {
//     labels: [
//       "Jan",
//       "Feb",
//       "Mar",
//       "Apr",
//       "May",
//       "Jun",
//       "Jul",
//       "Aug",
//       "Sep",
//       "Oct",
//       "Nov",
//       "Dec",
//     ],
//     datasets: [
//       {
//         label: "Sales Report",
//         data: [2, 3.4, 4, 5, 5.5, 5.6, 6, 7, 7.3, 7.7, 8.1, 8.2],
//         borderColor: "rgba(75, 192, 192, 1)",
//         borderWidth: 2,
//         fill: false,
//         tension: 0.4, // Curved line
//       },
//       {
//         label: "Line 2",
//         data: [3, 4, 3.8, 5.7, 6.2, 6.2, 6.6, 7.7, 7.3, 8.3, 8.1, 9.2],
//         borderColor: "rgba(255, 99, 132, 1)",
//         borderWidth: 2,
//         fill: false,
//         tension: 0.4, // Curved line
//       },
//     ],
//   };

//   const chartOptions = {
//     responsive: true,
//     plugins: {
//       legend: {
//         display: true,
//       },
//     },
//     scales: {
//       x: {
//         beginAtZero: true,
//       },
//       y: {
//         beginAtZero: true,
//       },
//     },
//   };

//   return <Line data={chartData} options={chartOptions} />;
// };

// export default LineChart;

import React, { useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const LineChart = () => {
  const chartRef = useRef(null);

  const chartData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Stock Price",
        data: [3, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9],
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
        tension: 0.5, // Slight curve for smoothness
        fill: false,
      },
      {
        label: "Stock Price",
        data: [3.5, 4.2, 5, 4.8, 6, 6.7, 6.3, 7.5, 7.8, 8.5, 8.9, 9.2],
        borderColor: "rgb(59, 13, 56)",
        borderWidth: 2,
        tension: 0.5, // Slight curve for smoothness
        fill: false,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        callbacks: {
          label: (context) => `Price: $${context.raw}`, // Custom tooltip
        },
      },
      crosshairLine: {
        // Custom plugin to draw the vertical hover line
        beforeTooltipDraw: (chart) => {
          const { ctx, chartArea, tooltip } = chart;

          if (tooltip?._active?.length) {
            const xPosition = tooltip._active[0].element.x;

            // Draw the vertical line
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(xPosition, chartArea.top);
            ctx.lineTo(xPosition, chartArea.bottom);
            ctx.lineWidth = 1;
            ctx.strokeStyle = "rgba(0, 0, 0, 0.5)"; // Line color
            ctx.stroke();
            ctx.restore();
          }
        },
      },
    },
    scales: {
      x: {
        beginAtZero: false,
      },
      y: {
        beginAtZero: false,
      },
    },
  };

  return <Line ref={chartRef} data={chartData} options={chartOptions} />;
};

export default LineChart;

import React, { useState } from "react";
import "./FullStack.scss";

import money from "../../assets/images/service/fullStack/money.png";
import data from "../../assets/images/service/fullStack/data.png";
import expert from "../../assets/images/service/fullStack/expert.png";
import partner from "../../assets/images/service/fullStack/partner.png";
import quick from "../../assets/images/service/fullStack/quick.png";
import save from "../../assets/images/service/fullStack/save.png";
import service from "../../assets/images/service/fullStack/service.png";
import team from "../../assets/images/service/fullStack/team.png";

export default function FullStack() {
  const formData = [
    {
      icon: money,
      title: "Save Money",
      description:
        "Using one agency for everything is often cheaper than hiring multiple specialists.",
    },
    {
      icon: team,
      title: "One Team, One Plan",
      description:
        "Everything is coordinated, making your marketing more effective.",
    },
    {
      icon: data,
      title: "Better Data",
      description:
        "With the same team looking at all the numbers, you get smarter, more insights.",
    },
    {
      icon: quick,
      title: "Quick changes",
      description:
        "If something’s not working, a full-service agency can fix it fast.",
    },
    {
      icon: partner,
      title: "Long Term Partner",
      description:
        "More than just a service, you get a team that’s invested in your growth.",
    },
    {
      icon: expert,
      title: "Expert Help",
      description:
        "Agencies like eHawkers know your industry well, helping you get ahead faster.",
    },
    {
      icon: save,
      title: "Save Time",
      description: "Dealing with one agency is less work for you.",
    },
    {
      icon: service,
      title: "All-in-one Service",
      description:
        "You get everything from one place, making your life easier.",
    },
  ];

  const [hoveredCard, setHoveredCard] = useState(null); // Track the hovered card's ID
  const [hoverStyle, setHoverStyle] = useState({});
  const [backgroundHoverPart, setBackgroundHoverPart] = useState(true);

  const handleMouseMove = (e, id) => {
    setBackgroundHoverPart(false);
    const card = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - card.left;
    const y = e.clientY - card.top;
    setHoveredCard(id); // Set the current hovered card
    setHoverStyle({
      transform: `translate(${x - 25}px, ${y - 25}px)`,
    });
  };

  const handleMouseLeave = () => {
    setHoveredCard(null); // Clear the hovered card when the mouse leaves
  };

  return (
    <div className="fullStack">
      <h1>
        Why Full-Stack <br /> Marketing Approach
      </h1>
      <p className="heading-dis">
        Making brands a damn site better. Brands win over fans when they’re
        brave enough <br /> to go beyond their creative comfort zone.
      </p>

      <div className="fullStackOuter">
        {formData.map((item, id) => {
          return (
            <div
              key={id}
              className="fullstackCard"
              onMouseMove={(e) => handleMouseMove(e, id)}
              onMouseLeave={handleMouseLeave} // Clear hover effect on mouse leave
            >
              <img src={item.icon} alt="" />
              <h4>{item.title} </h4>
              <p className="dis">{item.description}</p>
              {(hoveredCard === id || backgroundHoverPart) && ( // Show whitebg only for the hovered card
                <div
                  className="whitebg"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    pointerEvents: "none",
                    transition: "transform 0.2s ease",
                    ...hoverStyle,
                  }}
                ></div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import "./BrandPage.scss";
import "../Contact/Contact.scss";
import { strategyData } from "../../Constants/strategyAbout";
import { reletedWorkData } from "../../Constants/Reletedworks";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import BrandHome from "../../compenents/BrandComponent/BrandHome/BrandHome";
import BrandIceberge from "../../compenents/BrandComponent/brandIceberge/brandIceberge";
import Header from "../../compenents/Header/Header";

import gif from "../../assets/images/brandPage/gif.gif";
import AboutStrategy from "../../compenents/BrandComponent/AboutStrategy/AboutStrategy";
import ReletedWork from "../../compenents/BrandComponent/ReletedWork/ReletedWork";
import JoinForm from "../../compenents/JoinForm/JoinForm";

const mservice = [
  "Brand Positioning",
  "Rebranding",
  "In-store Branding",
  "Corporate Branding",
];

gsap.registerPlugin(ScrollTrigger);

export default function BrandPage() {
  return (
    <div className="BrandPage">
      <div className="brandNavHeader">
        <Header />
      </div>
      <div className="BrandHomeInner">
        <BrandHome
          ButtonHeading="Brand Solution"
          heading="Building <span>Identities</span>, <br /> Creating Legacies"
          shadow="0 4px 100px 50px rgba(234, 68, 53, 0.47)"
          gifSrc={gif}
          paragraph="Your brand is your handshake, your <br> voice, your story."
          mservice={mservice}
        />
      </div>
      <div className="brand-second-section">
        <BrandIceberge />
      </div>
      <div className="brand-third-section">
        <AboutStrategy
          title="What"
          span="Brand Marketing"
          strategyData={strategyData}
        />
      </div>
      <div className="brand-fourth-section">
        <ReletedWork
          heading="Our First Related Works"
          paragraph="These are some of our most successful projects, demonstrating our ability to push creative boundaries."
          reletedWorkData={reletedWorkData}
        />
      </div>

      <div className="brand-fifth-section">
        <div className="afterHeader">
          <div className="firstPart">
            <div className="headingOfContact">
              <h3>
                If you like our concept <br /> Let’s talk {""}
                <span className="contact-heading-mid">business.</span>{" "}
              </h3>
            </div>
            <div className="contactForm">
              <JoinForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

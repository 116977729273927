import React from "react";
import "./GrowthJourney.scss";

const carditemData = [
  {
    image: "https://via.placeholder.com/150",
    title: "Let’s Talk",
    content:
      "Book your call to get the ball rolling and discover how you can launch your growth to new heights.",
  },
  {
    image: "https://via.placeholder.com/150",
    title: "Gain Custom Insights",
    content:
      "Get over data-driven insights about your business and offer new knowledge to boost your brand.",
  },
  {
    image: "https://via.placeholder.com/150",
    title: "Title 1",
    content:
      "Content lorem ipsum dolor loexistent velit esse null tempor invidunt ut labore et dolore magna aliqu sapiente",
  },
  {
    image: "https://via.placeholder.com/150",
    title: "Title 1",
    content:
      "Content lorem ipsum dolor loexistent velit esse null tempor invidunt ut labore et dolore magna aliqu sapiente",
  },
];

export default function GrowthJourney() {
  return (
    <div className="gourneycontainer">
      <div className="leftbg">
        <div className="innerbg"></div>
      </div>
      <div className="GrowthJourney">
        <h1>
          The Growth Journey
          <br /> Let’s do great work together
        </h1>

        <div className="cardOuter">
          <div className="card-section">
            {carditemData.map((item, id) => {
              return (
                <div className="cardcontainers">
                  <div key={id} className="card-item">
                    <div className="bgwhite"></div>

                    <img src={item.image} alt={item.title} />
                    <div className="information">
                      <h3>{item.title}</h3>
                      <p>{item.content}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="whiteline">
            <div className="lineOuter">
              <div className="line"></div>
              <button>Phase 01</button>
              <div className="line"></div>
            </div>
            <div className="lineOuter">
              <div className="line"></div>
              <button>Phase 02</button>
              <div className="line"></div>
            </div>
            <div className="lineOuter">
              <div className="line"></div>
              <button>Phase 03</button>
              <div className="line"></div>
            </div>
            <div className="lineOuter">
              <div className="line"></div>
              <button>Phase 04</button>
              <div className="line"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="rightbg"></div>
    </div>
  );
}

import React, { useEffect, useRef } from "react";
import "./PerfomanceWorkflow.scss";
import gsap from "gsap";
// import { useGSAP } from "@gsap/react";

import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// import { useGSAP } from "./path/to/your/hooks";

// import img1 from "../../../assets/images/service/challenge/Group 89.png";
// import img2 from "../../../assets/images/service/challenge/Group 90.png";

const perfomanceData = [
  {
    image: "https://via.placeholder.com/150",
    title: "Title 1",
    content:
      "Everything we do at eHawkers is tied to return on investment. During our initial brainstorming and ideation sessions, we will define your PPC goals, analyze any historical data you may have, and craft a clear plan that highlights where and how we can improve your ROI. By analyzing cost per click, conversion rate, cost per acquisition, and revenue, you can rest assured that our services will provide the necessary value. We can also tie our successful conversions back into our optimization, resulting in a higher converting campaign moving forward.",
    WorkInvolved: ["Review hostorial Data", "Goal Analysis", "Brainstroming"],
  },
  {
    image: "https://via.placeholder.com/150",
    title: "Title 1",
    content:
      "Everything we do at eHawkers is tied to return on investment. During our initial brainstorming and ideation sessions, we will define your PPC goals, analyze any historical data you may have, and craft a clear plan that highlights where and how we can improve your ROI. By analyzing cost per click, conversion rate, cost per acquisition, and revenue, you can rest assured that our services will provide the necessary value. We can also tie our successful conversions back into our optimization, resulting in a higher converting campaign moving forward.",
    WorkInvolved: ["Review hostorial Data", "Goal Analysis", "Brainstroming"],
  },
  {
    image: "https://via.placeholder.com/150",
    title: "Title 1",
    content:
      "Everything we do at eHawkers is tied to return on investment. During our initial brainstorming and ideation sessions, we will define your PPC goals, analyze any historical data you may have, and craft a clear plan that highlights where and how we can improve your ROI. By analyzing cost per click, conversion rate, cost per acquisition, and revenue, you can rest assured that our services will provide the necessary value. We can also tie our successful conversions back into our optimization, resulting in a higher converting campaign moving forward.",
    WorkInvolved: ["Review hostorial Data", "Goal Analysis", "Brainstroming"],
  },
  {
    image: "https://via.placeholder.com/150",
    title: "Title 1",
    content:
      "Everything we do at eHawkers is tied to return on investment. During our initial brainstorming and ideation sessions, we will define your PPC goals, analyze any historical data you may have, and craft a clear plan that highlights where and how we can improve your ROI. By analyzing cost per click, conversion rate, cost per acquisition, and revenue, you can rest assured that our services will provide the necessary value. We can also tie our successful conversions back into our optimization, resulting in a higher converting campaign moving forward.",
    WorkInvolved: ["Review hostorial Data", "Goal Analysis", "Brainstroming"],
  },
];

export default function PerfomanceWorkflow() {
  const divs = document.querySelectorAll(".cardOuter");
  // useGSAP(() => {
  gsap.to(".cardOuter", {
    transform: "translateX(-120%)",
    scrollTrigger: {
      trigger: ".home-container4",
      scroller: "body",
      start: "top -25%",
      end: "top -150%",
      scrub: 2,
      pin: true,
    },
  });

  gsap.to(".home-container2", {
    opacity: 1,
    duration: 0.5,
    scrollTrigger: {
      trigger: ".home-container2",
      scroller: "body",
      start: "top 30%",
      end: "top 20%",
      scrub: 2,
    },
  });
  // });

  gsap.to(".home-container4 .words", {
    // filter: "blur(10px)",
    delay: 1,
    scrollTrigger: {
      trigger: ".home-container4",
      scroller: "body",
      start: "top 50%",
      end: "top 25%",
      scrub: true,
    },
  });

  return (
    <div className="Performance-Marketing-Workflow">
      <div className="bgbgbg"></div>
      <div className="bgbgbg1"></div>
      <h1 className="Performance-hd">Performance Marketing Workflow</h1>
      <p className="p-paragraph">
        Here is the overview of a medium sized project. Lorem ipsum dolor sit
        amet, <br /> consectetur adipiscing elit. Donec et ipsum et lorem
        pharetra commodo ac vitae eros.
      </p>

      <div className="cardOuter">
        <div className="card-section">
          {perfomanceData.map((item, id) => {
            return (
              <div className="cardcontainers">
                <div key={id} className="card-item">
                  <div className="bgwhite"></div>

                  <img src={item.image} alt={item.title} />
                  <div className="information">
                    <h3>{item.title}</h3>
                    <p className="item-details">{item.content}</p>
                    <div className="work-involved">
                      <h5>Work Involved</h5>
                      <div className="work-container">
                        {item.WorkInvolved.map((work, index) => (
                          <p className="wk-type" key={index}>
                            {work}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="whiteline">
          <div className="lineOuter">
            <div className="line"></div>
            <button>Phase 01</button>
            <div className="line"></div>
          </div>
          <div className="lineOuter">
            <div className="line"></div>
            <button>Phase 02</button>
            <div className="line"></div>
          </div>
          <div className="lineOuter">
            <div className="line"></div>
            <button>Phase 03</button>
            <div className="line"></div>
          </div>
          <div className="lineOuter">
            <div className="line pdd" style={{ paddingLeft: "22rem" }}></div>
            <button>Phase 04</button>
            <div className="line"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

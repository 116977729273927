import React from "react";
import "./ReletedWork.scss";

export default function ReletedWork({ heading, paragraph, reletedWorkData }) {
  return (
    <div className="ReletedWork">
      <h1 className="releted-heading">{heading}</h1>
      <p
        className="releted-paragraph"
        dangerouslySetInnerHTML={{ __html: paragraph }}
      >
        {/* {paragraph} */}
      </p>

      <div className="releted-cards">
        {reletedWorkData.map((work, id) => (
          <div className="releted-card" key={id}>
            <div className="releted-details-overlay">
              <h2 className="releted-card-heading">{work?.heading}</h2>
              <p
                className="releted-card-details"
                dangerouslySetInnerHTML={{ __html: work?.details }}
              ></p>

              <div className="releted-desig">
                {work.degignation?.map((desig, desigId) => (
                  <p className="kkk" key={desigId}>
                    {desig}
                  </p>
                ))}
              </div>
            </div>
            <img className="bgimgkk" src={work?.img} alt={work?.heading} />
          </div>
        ))}
      </div>
    </div>
  );
}

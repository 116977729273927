import fenton from "../assets/images/home/fenton.png";
import fent from "../assets/images/home/fen.png";
import image from "../assets/images/home/clients/image.png";
import tempus from "../assets/images/home/clients/Web card 2.png";
import jet from "../assets/images/home/clients/Web card 3.png";

export const clientData = [
  {
    link: "https://fantom-e.vercel.app",
    image: fenton,
    title: "Fenton",
    paragraph:
      "Designed and developed a modern, user-friendly website for Fenton using the MERN (MongoDB, Express.js, React.js, Node.js) technology stack. Focused on creating an engaging UI/UX design to ensure seamless navigation and an enhanced user experience. The project emphasized responsive design, ensuring compatibility across devices, and included features tailored to meet Fenton business needs.",
    category: "Technology",

    buttons: [
      { text: "UI/UX Design", link: "/project2" },
      { text: "Web Development", link: "/contact2" },
      { text: "Graphic Design", link: "/learn-more2" },
    ],
  },
  {
    link: "https://www.tempusfurniture.in/",

    image: tempus,
    title: "Tempus",
    paragraph:
      "Developed a modern, user-friendly website for Tempus using the MERN stack, focusing on seamless UI/UX design for an intuitive experience. The website is fully responsive and customized to meet Tempus unique business needs.",

    category: "Media",
    height: "smallImg",
    buttons: [
      { text: "UI/UX Design", link: "/project2" },
      { text: "Web Development", link: "/contact2" },
      { text: "Graphic Design", link: "/learn-more2" },
    ],
  },
  {
    link: "https://www.jettradelogistics.in/",
    image: jet,
    title: "jet logistic",
    paragraph:
    "Designed and developed the website for Jet Trade Logistics to showcase their logistics and supply chain solutions effectively. The website features a clean and responsive design, ensuring seamless navigation and accessibility across all devices, tailored to meet the company's operational needs and enhance user engagement.",
    category: "Brand",
    height: "smallImg",
    buttons: [
      { text: "UI/UX Design", link: "/project2" },
      { text: "Web Development", link: "/contact2" },
      { text: "Graphic Design", link: "/learn-more2" },
    ],
  },
  {
    link: "#",

    image: fent,
    title: "Client 4",
    paragraph:
      "Client 4 trusted us with a complex project, and the results speak for themselves.",
    category: "Marketing",
    height: "smallImg",
    buttons: [
      { text: "UI/UX Design", link: "/project2" },
      { text: "Web Development", link: "/contact2" },
      { text: "Graphic Design", link: "/learn-more2" },
    ],
  },
  {
    link: "#",

    image: fent,
    title: "Client 5",
    paragraph:
      "Client 5 came to us for a creative solution, and we delivered beyond expectations.",
    category: "Strategy",
    height: "smallImg",
    buttons: [
      { text: "UI/UX Design", link: "/project2" },
      { text: "Web Development", link: "/contact2" },
      { text: "Graphic Design", link: "/learn-more2" },
    ],
  },
];

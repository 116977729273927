import React from "react";
import "./PostJobBox.scss";
import { useNavigate } from "react-router-dom";

const PostJobBox = ({ item }) => {
  function formatNumber(value) {
    if (value >= 10000000) {
      return (value / 10000000).toFixed(1) + " Cr"; // Crores
    } else if (value >= 100000) {
      return (value / 100000).toFixed(1) + " L"; // Lakhs
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + " K"; // Thousands
    }
    return value.toString(); // No formatting needed
  }

  const navigate = useNavigate();

  return (
    <div>
      <div className="job-section-wrapper">
        <div className="job-section-content">
          <div className="job-section-header">
            <p className="job-title">{item.position}</p>
            <div className="job-specs">
              <div className="spec-item">
                <p className="spec-detail">Fulltime</p>
              </div>
              <div className="spec-item">
                <p className="spec-detail">Onsite</p>
              </div>
              <div className="spec-item">
                <p className="spec-detail">{formatNumber(item.lowSalary)}</p>
              </div>
            </div>
          </div>
          <div className="job-section-footer">
            <button onClick={() => navigate(`/SingleJobPost/${item?._id}`)}>
              Apply Now
            </button>
            <p className="applied-count">
              <i className="bi bi-people"></i> &nbsp; 24 Applied
            </p>
          </div>
        </div>
        <div className="job-section-blur-effect"></div>
      </div>
    </div>
  );
};

export default PostJobBox;

import React from "react";
import "./TechService.scss";
import "../MarketingService/MarketingService.scss";
import TechServiceDetails from "../../compenents/TechServiceDetails/TechServiceDetails";
import BrandHome from "../../compenents/BrandComponent/BrandHome/BrandHome";
import { reletedWorkData } from "../../Constants/Reletedworks";

import PerfomanceWorkflow from "../../compenents/marketingServiceComts/perfomanceWorkflow/PerfomanceWorkflow";
import JoinForm from "../../compenents/JoinForm/JoinForm";
import ReletedWork from "../../compenents/BrandComponent/ReletedWork/ReletedWork";
import techmg from "../../assets/images/tech/techmg.gif";
import Header from "../../compenents/Header/Header";
const BMHomedata = {
  ButtonHeading: "Tech Solutions",
  heading: `Some cool punchline <br/> about <span style="
     background:
  linear-gradient(90.98deg, #293061 14.23%,rgb(142, 144, 148) 110.72%);
  
      -webkit-background-clip: text;
      color: transparent;
      display: inline-block;
    ">tech solution </span>`,
  bg: "background: radial-gradient(164.38% 728.43% at 0% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%),linear-gradient(90deg, #34A853 0%, #5DE18B 100%);",

  description:
    "In just 7 seconds, a lasting impression is made. Our one goal is to sweep your target audience off their feet under 5.",
  gifSrc: techmg,
  shadow: "0 4px 100px 50px #293061",

  paragraph:
    "We craft campaigns that resonate, engage, <br/> and convert, ensuring your message reaches <br/> the right audience at the right time.",
};
const mservice = ["Tech", "Work"];

export default function TechService() {
  return (
    <div className="TechService">
      <div className="tech-header">
        <Header />
      </div>
      <div className="tech-hero">
        <BrandHome {...BMHomedata} mservice={mservice} customStyle={true} />
      </div>

      <div className="tech-service">
        <TechServiceDetails />
      </div>
      <div className="service-perfomanceWorkflow">
        <PerfomanceWorkflow />
      </div>
      <div className="reletedwork-section">
        <ReletedWork
          heading="Our First Related Works"
          paragraph=" aThese are some of our most successful projects, demonstrating our <br/> ability to push creative boundaries."
          reletedWorkData={reletedWorkData}
        />
      </div>
      <div className="ms-section">
        <div className="afterHeader">
          <div className="firstPart">
            <div className="headingOfContact">
              <h3>
                If you like our concept <br /> Let’s talk {""}
                <span className="contact-heading-mid">business.</span>{" "}
              </h3>
            </div>
            <div className="ms-contactForm">
              <JoinForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import ja from "../assets/images/home/teams/01.png";
import sa from "../assets/images/home/teams/01.png";
import aa from "../assets/images/home/teams/k.png";
// import aa from "../assets/images/home/teams/03.png";

export const employeeData = [
  {
    id: 1,
    imgSrc: aa,
    alt: "icon..",
    name: "samriddhi acholiya",
    position: "Software Engineer",
    details :"lorem lorem ipsum dolor sit amet, consectet null amet, sed diam",
    socialLinks: {
      instagram: "https://instagram.com/johndoe",
      linkedin: "https://linkedin.com/in/johndoe",
      twitter: "https://twitter.com/johndoe",
    },
  },
  {
    id: 2,
    imgSrc: ja,
    alt: "icon..",
    name: "Jane Smith",
    position: "Product Manager",
    details :"lorem lorem ipsum dolor sit amet, consectet null amet, sed diam",

    socialLinks: {
      instagram: "https://instagram.com/janesmith",
      linkedin: "https://linkedin.com/in/janesmith",
      twitter: "https://twitter.com/janesmith",
    },
  },
  {
    id: 3,
    imgSrc: aa,
    alt: "icons...",
    name: "Mike Johnson",
    details :"lorem lorem ipsum dolor sit amet, consectet null amet, sed diam",

    position: "UI/UX Designer",
    socialLinks: {
      instagram: "https://instagram.com/mikejohnson",
      linkedin: "https://linkedin.com/in/mikejohnson",
      twitter: "https://twitter.com/mikejohnson",
    },
  },
  {
    id: 3,
    imgSrc: aa,
    alt: "icons...",
    name: "Mike Johnson",
    details :"lorem lorem ipsum dolor sit amet, consectet null amet, sed diam",

    position: "UI/UX Designer",
    socialLinks: {
      instagram: "https://instagram.com/mikejohnson",
      linkedin: "https://linkedin.com/in/mikejohnson",
      twitter: "https://twitter.com/mikejohnson",
    },
  },
 
 

  // Add similar objects for the rest of the employees.
];

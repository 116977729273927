import strategyImg1 from "../assets/images/brandPage/frame1.png";
import strategyImg2 from "../assets/images/brandPage/frame2.png";
import strategyImg3 from "../assets/images/brandPage/frame3.png";
export const strategyData = [
  {
    image: "",
    heading: "Business Growth Strategies",
    details:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas <br/> eu condimentum diam. Praesent non euismod turpis. Nullam <br/> vel rhoncus elit. Donec eget purus nec ante bibendum rhoncus. ",

    specification: [
      "Go-to-market strategies for new launches",
      "Entry strategies for untapped markets",
      "Partnership and collaboration opportunities",
    ],
  },
  {
    image: "",
    heading: "Market Analysis & Insights",
    details:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas <br/> eu condimentum diam. Praesent non euismod turpis. Nullam <br/> vel rhoncus elit. Donec eget purus nec ante bibendum rhoncus. ",

    specification: [
      "Industry and competitor benchmarking ",
      "Target audience segmentation and persona creation ",
      "Consumer behavior trends and analytic ",
    ],
  },
  {
    image: "",
    heading: "Digital Transformation Strategies",
    details:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas <br/> eu condimentum diam. Praesent non euismod turpis. Nullam <br/> vel rhoncus elit. Donec eget purus nec ante bibendum rhoncus. ",

    specification: [
      "Process automation for efficiency ",
      "Cloud-based collaborative tools adoption ",
      "Employee upskilling for technology utilization ",
    ],
  },
  {
    image: "",
    heading: "Sales Optimization ",
    details:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas <br/> eu condimentum diam. Praesent non euismod turpis. Nullam <br/> vel rhoncus elit. Donec eget purus nec ante bibendum rhoncus. ",
    specification: [
      "Sales funnel restructuring for higher ROI ",
      "Advanced CRM workflows for lead nurturing ",
      "Channel-specific sales strategies ",
      "Contingency planning for operational disruptions ",
      "Crisis Management Strategy",
      "Brand reputation recovery plans",
    ],
  },
  //   {
  //     image: "",
  //     heading: "",
  //     details: "",
  //     specification: [""],
  //   },
];
export const strategyData1 = [
  {
    image: "",
    heading: "Business Growth Strategies",
    details:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas <br/> eu condimentum diam. Praesent non euismod turpis. Nullam <br/> vel rhoncus elit. Donec eget purus nec ante bibendum rhoncus. ",

    specification: [
      "Go-to-market strategies for new launches",
      "Entry strategies for untapped markets",
      "Partnership and collaboration opportunities",
    ],
  },
  {
    image: "",
    heading: "Market Analysis & Insights",
    details:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas <br/> eu condimentum diam. Praesent non euismod turpis. Nullam <br/> vel rhoncus elit. Donec eget purus nec ante bibendum rhoncus. ",

    specification: [
      "Industry and competitor benchmarking ",
      "Target audience segmentation and persona creation ",
      "Consumer behavior trends and analytic ",
    ],
  },
  {
    image: "",
    heading: "Digital Transformation Strategies",
    details:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas <br/> eu condimentum diam. Praesent non euismod turpis. Nullam <br/> vel rhoncus elit. Donec eget purus nec ante bibendum rhoncus. ",

    specification: [
      "Process automation for efficiency ",
      "Cloud-based collaborative tools adoption ",
      "Employee upskilling for technology utilization ",
    ],
  },
  {
    image: "",
    heading: "Sales Optimization ",
    details:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas <br/> eu condimentum diam. Praesent non euismod turpis. Nullam <br/> vel rhoncus elit. Donec eget purus nec ante bibendum rhoncus. ",
    specification: [
      "Sales funnel restructuring for higher ROI ",
      "Advanced CRM workflows for lead nurturing ",
      "Channel-specific sales strategies ",
      "Contingency planning for operational disruptions ",
      "Crisis Management Strategy",
      "Brand reputation recovery plans",
    ],
  },
  //   {
  //     image: "",
  //     heading: "",
  //     details: "",
  //     specification: [""],
  //   },
];

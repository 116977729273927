import React, { useState } from "react";
import "./JobForm.scss";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function JobForm({ onClose, department, role }) {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    location: "",
    otherDetails: "",
  });

  const validate = () => {
    // let newErrors = {};

    // Name validation (minimum 3 characters)
    if (formData.name.trim().length < 3) {
      return "Name should be at least 3 characters long.";
    }

    // Phone Number validation (must be digits and 10 characters long)
    if (!/^\d{10}$/.test(formData.phoneNumber.trim())) {
      return "Phone number should be 10 digits.";
    }

    // Email validation (must include @ symbol)
    if (!/\S+@\S+\.\S+/.test(formData.email.trim())) {
      return "Email should be valid";
    }

    // // Message validation (minimum 3 characters)
    // if (formData.message.trim().length < 3) {
    //   return "Message should be at least 3 characters long.";
    // }
    // Message validation (minimum 3 characters)
    if (formData.location.trim().length < 3) {
      return "location should be at least 3 characters long.";
    }

    // Return true if no errors, otherwise false
    return "";
  };

  const [resume, setResume] = useState(null);

  const convertPdfUrl = async () => {
    const formData = new FormData();
    formData.append("pdf", resume);
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "api/pdfFile",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("response: ", response?.data);
      if (response?.data?.success) {
        return response?.data?.url;
        // Do something with the URL if needed
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validate();
    if (errors.length != 0) {
      toast.warn(errors, {
        position: "bottom-right",
        autoClose: 4000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
      return;
    }

    const pdfUrl = await convertPdfUrl();

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_BASE_URL + "api/CreateCandidate",
      data: {
        name: formData.name,
        emailId: formData.email,
        phoneNumber: formData.phoneNumber,
        cv: pdfUrl,
        location: formData.location,
        department: department,
        role: role,
        otherDetails: formData.otherDetails,
      },
    };
    // }
    console.log(handleSubmit);

    await axios
      .request(config)
      .then((response) => {
        if (response?.data?.status == true) {
          console.log("dfghj", response.data);

          toast.success("Thank You For Filled Form", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
          navigate("/career");
        } else if (response?.data?.status == false) {
          if (response?.data?.response_code == 500) {
            toast.error("Internal Server Error", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          } else if (response?.data?.response_code == 404) {
            toast.error("Internal Server Error", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          } else {
            toast.error("Internal Server Error", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.response_code == 401) {
          toast.error("Your token is expired. Please login again.", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        } else {
          toast.warn("Internal Server Error", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        }
      });
  };

  return (
    <div>
      <div className="job-main shodow">
        <form className="form" onSubmit={handleSubmit}>
          <i class="bi bi-x-circle text-end fs-4 danger" onClick={onClose}>
            {" "}
          </i>
          <h2 className="text-center text-white">Fill Details</h2>
          <div className="formbox">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              placeholder="Enter Your Full Name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="formbox">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              placeholder="Enter Your Email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="formbox">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              type="number"
              name="phoneNumber"
              placeholder="Enter Your Phone Number"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </div>
          <div className="formbox">
            <label htmlFor="location">Location</label>
            <input
              type="text"
              name="location"
              placeholder="Enter Your Location"
              value={formData.location}
              onChange={handleChange}
            />
          </div>
          <div className="formbox">
            <label htmlFor="otherDetails">Other Details</label>
            <textarea
              name="otherDetails"
              placeholder="Describe Your Self..."
              value={formData.otherDetails}
              onChange={handleChange}
            />
          </div>
          <div className="formbox">
            <label htmlFor="resume">Upload Your Resume</label>
            <input
              type="file"
              id="resume"
              name="resume"
              className="resume"
              accept=".pdf,.doc,.docx"
              required
              onChange={(e) => setResume(e.target.files[0])}
            />
          </div>
          {/* <div className="formbox box2">
            <div>
              <input type="checkbox" />
            </div>
            <p>
              &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Sint voluptas tempora, incidunt dignissimos, esse voluptate
              similique cupiditate vero nulla, quos non optio!
            </p>
          </div> */}
          <div className="formbox text-center">
            <button type="submit" className="btn" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
export default JobForm;

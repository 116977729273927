import React, { useEffect, useRef } from "react";
import "./MixblendCursor.scss";

function MixblendCursor() {
  const cursorRef = useRef(null);

  const handleMouseMove = (e) => {
    if (cursorRef.current) {
      cursorRef.current.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
    }
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const cursorStyle = {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    position: "fixed",
    top: "-5px",
    left: "-5px",
    backgroundColor: "white",
    pointerEvents: "none",
    mixBlendMode: "difference",
    transition: "all .2s linear",
    zIndex: 99999,
    backdropFilter: "none",
  };

  return <p className="mixbcorsor" style={cursorStyle} ref={cursorRef}></p>;
}

export default MixblendCursor;

import React from "react";
import "./AboutHome.scss";
import earth from "../../assets/images/marketing service/earth.png";

import go from "../../assets/images/about/abh.png";
export default function AboutHome() {
  return (
    <div className="AboutHome">
      <div className="circle">
        <div className="subinner">
          <div className="bgTopBox">
            <div className="kkk">
              <div className="centername">Ideate</div>
              <div className="name1">Strategize</div>
              <div className="name2">Ideate</div>
              <div className="name3">Create</div>
              <div className="name4">Deliver</div>
            </div>
            <div className="top-names">
              {/* <div className="cen">Ideate</div> */}
              <div className="nam1">Strategize</div>
              {/* <div className="nam2">Ideate</div> */}
              {/* <div className="nam3">Create</div> */}
              <div className="nam4">Deliver</div>
            </div>
            <div className="top-lay-names">
              <div className="tln">
                <div className="nam11">Strategize</div>
                <div className="nam22">Ideate</div>
                <div className="nam33">Create</div>
                <div className="nam44">Deliver</div>
              </div>
            </div>

            <div className="top-lay-names2">
              <div className="tln">
                <div className="nam11">Strategize</div>
                <div className="nam22">Ideate</div>
                <div className="nam33">Create</div>
                <div className="nam44">Deliver</div>
              </div>
            </div>
          </div>

          <img src={earth} alt="" className="backgrounds " />
          {/* <div className="go"></div> */}
          <img src={go} alt="" className="abh" />
        </div>
      </div>
      <div className="homeinner">
        <h1>
          All in one <span>Growth</span> Team
        </h1>
        <p>
          Your Creative, Media & Technology Transformation Partner. <br />
          We take care of everything Digital. We are the experts in boosting
          your brand,
          <br /> elevating your online visibility, and propelling your business
          to success
        </p>
      </div>
    </div>
  );
}

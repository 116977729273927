import img from "../assets/images/newPortfolio/k.jpg";
import withoutname from "../assets/images/header/Group 11.png";
import mylogo from "../assets/images/home/footer/Dark - H - T 1.png";

import html from "../assets/images/newPortfolio/html.jpeg";
import css from "../assets/images/newPortfolio/css.png";
import js from "../assets/images/newPortfolio/js.jpg";
import mongo from "../assets/images/newPortfolio/mongo.png";
import react from "../assets/images/newPortfolio/react.png";

// tempus .......
import logo from "../assets/images/newPortfolio/tempus/logo.PNG";
import img1 from "../assets/images/newPortfolio/tempus/IMG-20250121-WA0028.jpg";
import img2 from "../assets/images/newPortfolio/tempus/IMG-20250121-WA0029.jpg";
import img4 from "../assets/images/newPortfolio/tempus/IMG-20250121-WA0031.jpg";
import img5 from "../assets/images/newPortfolio/tempus/IMG-20250121-WA0032.jpg";
import img6 from "../assets/images/newPortfolio/tempus/IMG-20250121-WA0033.jpg";

// fenton
import flogo from "../assets/images/newPortfolio/fenton/fenton.jpg";
import fimg1 from "../assets/images/newPortfolio/fenton/IMG-20250121-WA0030.jpg";
import fimg2 from "../assets/images/newPortfolio/fenton/IMG-20250121-WA0034.jpg";
import fimg3 from "../assets/images/newPortfolio/fenton/IMG-20250121-WA0035.jpg";
import fimg4 from "../assets/images/newPortfolio/fenton/IMG-20250121-WA0036.jpg";
import fimg5 from "../assets/images/newPortfolio/fenton/IMG-20250121-WA0037.jpg";
import fimg6 from "../assets/images/newPortfolio/fenton/IMG-20250121-WA0038.jpg";

// bhharti
import blogo from "../assets/images/newPortfolio/bharti/IMG-20250121-WA0016.jpg";
import bimg2 from "../assets/images/newPortfolio/bharti/IMG-20250121-WA0017.jpg";
import bimg3 from "../assets/images/newPortfolio/bharti/IMG-20250121-WA0018.jpg";
import bimg4 from "../assets/images/newPortfolio/bharti/IMG-20250121-WA0019.jpg";
import bimg5 from "../assets/images/newPortfolio/bharti/IMG-20250121-WA0020.jpg";

// goa
import glogo from "../assets/images/newPortfolio/goa/IMG-20250121-WA0010.jpg";
import gimg1 from "../assets/images/newPortfolio/goa/IMG-20250121-WA0011.jpg";
import gimg2 from "../assets/images/newPortfolio/goa/IMG-20250121-WA0012.jpg";
import gimg3 from "../assets/images/newPortfolio/goa/IMG-20250121-WA0013.jpg";
import gimg4 from "../assets/images/newPortfolio/goa/IMG-20250121-WA0014.jpg";
import gimg5 from "../assets/images/newPortfolio/goa/IMG-20250121-WA0015.jpg";

//jet
import jimg1 from "../assets/images/newPortfolio/jet/IMG-20250121-WA0021.jpg";
import jimg2 from "../assets/images/newPortfolio/jet/IMG-20250121-WA0023.jpg";
import jimg3 from "../assets/images/newPortfolio/jet/IMG-20250121-WA0024.jpg";
import jimg4 from "../assets/images/newPortfolio/jet/IMG-20250121-WA0025.jpg";
import jimg5 from "../assets/images/newPortfolio/jet/IMG-20250121-WA0026.jpg";
import jimg6 from "../assets/images/newPortfolio/jet/IMG-20250121-WA0027.jpg";

// unipride
import uimg1 from "../assets/images/newPortfolio/unipride/1.jpg";
import uimg2 from "../assets/images/newPortfolio/unipride/2.jpg";
import uimg3 from "../assets/images/newPortfolio/unipride/3.jpg";
import uimg4 from "../assets/images/newPortfolio/unipride/4.jpg";
import uimg5 from "../assets/images/newPortfolio/unipride/5.jpg";
import uimg6 from "../assets/images/newPortfolio/unipride/6.jpg";

export const newPortData = [
  {
    id: 1,
    logo: mylogo,
    bg: "red",
    title: "E-hawkers Marketing",
    image1: [img, img],
    image2: [img, img],
    description:
      "We craft smart, innovative, creative & handcrafted strategies leveraging cutting- edge technologies to generate the right leads converting into revenue for your business. We focus on building funnels that target people based on the level of interest, ensuring that every buck is appropriately spent where it matters most. Our main goal is to work as an extension of your team, so we’re available as much as you need us. We adapt to whatever work style is best suited for you. Our team works with clients from a variety of B2B and B2C industries. As an Indian company, we geek out over outdoor retailers and e-commerce, but you’ll also find us doing deep dives into SaaS and manufacturing industries. No matter your industry, we’ve crafted a digital marketing strategy for it",

    technology: [html, css, js, mongo, react],
  },
  {
    id: 2,
    logo: flogo,
    alt: "fenton",
    bg: "#4B96DB",
    title: "Fenton",
    description:
      "From Central India to Global Leadership, Revolutionizing the Chemical Industry Our relentless pursuit of innovation, ethical practices, and unparalleled customer satisfaction has established us as industry leaders. With decades of expertise, advanced technology, and strategic partnerships, we deliver comprehensive chemical solutions to meet   the evolving needs of businesses worldwide.",

    image1: [fimg4, fimg2],
    image2: [fimg3, fimg6],
    technology: [html, css, js, mongo, react],
  },
  {
    id: 3,
    logo: logo,
    bg: "red",
    title: "Tempus Furniture",
    image1: [img1, img2],
    image2: [img4, img5],
    description:
      "We are a fast growing and one of the most promising office and institutional furniture manufacturing companies in India. In the experienced individuals from the furniture fraternity and just the right infrastructure, that gets renewed from time to time to cope up with ever changing demands of the market, we have just the right ingredients to call ourselves progressive and foresighted. Our state of art manufacturing facilities at two major cities of India i.e. Bangalore in South and Indore in Central India with modern plant and machinery enable us to cater to the requirements of both panel based furniture and Metal Furniture too.",
    technology: [html, css, js, mongo, react],
  },
  {
    id: 4,
    logo: glogo,
    bg: "green",
    title: "Goa realty",
    image1: [gimg1, gimg2],
    image2: [gimg5, gimg4],
    description:
      "With deep expertise in Goa’s real estate market, we identify high-potential investment opportunities with maximized returns and minimized risks. Our secure investment models offer both fixed-return and revenue-sharing options, designed for transparency and financial security. Every property is legally sound and bank-approved, ensuring peace of mind. Through our unique “Our Land, Your Development” approach, we partner with builders, eliminating land costs while boosting profit potential. We focus on high-growth areas, including premium beachside villas and luxury apartments, and handle end-to-end project management, maximizing rental yields and providing investors with a seamless experience and passive income.",
    technology: [html, css, js, mongo, react],
  },
  {
    id: 5,
    logo: uimg3,
    bg: "#3D77D6",
    title: "UniPride",
    image1: [uimg1, uimg2],
    image2: [uimg4, uimg5],
    description:
      "We Unipride, are a leading manufacturer of high-quality uniforms and workwear. With a commitment to excellence, we specialise in crafting durable and stylish attire tailored to meet the specific needs of various industries. Our focus on innovation and customer satisfaction sets us apart in the realm of professional attire.",
    technology: [html, css, js, mongo, react],
  },
  {
    id: 5,
    logo: jimg3,
    bg: "red",
    title: "Jet logistics",
    image1: [jimg6, jimg2],
    image2: [jimg4, jimg5],
    description:
      "Expertise of Experience is the cornerstone of success in transportation and logistics. Our skilled workforce and state-of-the-art equipment ensure flawless transportation, including Hydraulic Axles and Low Bed Trailers. From navigating rough terrain to handling oversized cargo, we excel in challenging environments. Safety is our priority, upheld by our customer-centric approach and ethical standards.",
    technology: [html, css, js, mongo, react],
  },
];
